import React from "react";
import { Icon } from "~/components/elements/Icon";

export enum SubheadingIconOption {
	Microchip = "microchip",
	Platform = "platform",
	Expertise = "expertise",
	Analytics = "analytics",
	Solutions = "solutions",
	MagnifyingGlass = "MagnifyingGlass",
	Legal = "legal",
	squareStack = "squareStack",
}

export interface SubheadingProps {
	icon?: SubheadingIconOption;
	Type?: string;
	text: string;
}

export const Subheading = ({ text, icon, Type = "h3" }: SubheadingProps) => {
	if (!text) return null;

	return (
		<div className="flex max-w-max items-center rounded-[80px] bg-white bg-opacity-20">
			<div className="max-w-max rounded-full bg-white p-[7px]">
				<Icon type={icon} />
			</div>
			<span className="px-[10px] pb-0.5 pr-[17px] font-heading text-[16px] leading-4">{text}</span>
		</div>
	);
};

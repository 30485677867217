import React from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import { WpPage_Page_FlexibleContent_Usps_Usps } from "src/_generated/types";
import { useWindowSize } from "react-use";

const UspsItemAlt2 = ({ heading, image, content, i }: WpPage_Page_FlexibleContent_Usps_Usps) => {
	const { width } = useWindowSize();

	return (
		<div className="items-bottom usps-alt-item-2 flex h-full w-full flex-col items-start px-0 py-12 md:px-12 md:py-0">
			<Image className="w-full max-w-[82px] md:max-w-[105px]" image={image} />

			<div className="mt-6 space-y-[18px] md:mt-[66px]">
				<Heading as="h4" size={HeadingSizeOption.h4} text={heading} />

				<BodyCopy text={content} size={BodyCopySizeOption.Default} />
			</div>
		</div>
	);
};

export default UspsItemAlt2;

import React, { useState } from "react";
import { Section } from "~/components/elements/Section";
import { WpPost_Article_FlexibleContent_Video } from "src/_generated/types";
import Image from "~/components/elements/Image";
import { Icon, IconType } from "~/components/elements/Icon";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

export default function Video({ image, video, caption, section }: WpPost_Article_FlexibleContent_Video) {
	const [isModalOpen, setIsModalOpen] = useState(false);

	if (typeof window === "undefined" || !video) return null;

	return (
		<Section {...section} paddingBottom="none" paddingTop="none">
			<div className="flexible-video flex flex-col px-0">
				<div className="group relative cursor-pointer" onClick={() => setIsModalOpen(true)}>
					{/* Image */}
					<Image image={image} />

					{/* Play icon overlay */}
					<div className="absolute left-1/2 top-1/2 z-10 flex h-28 w-28 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-white text-dark-purple">
						{/* Circle background */}
						<div className="absolute inset-0 h-28 w-28 rounded-full bg-white transition-transform duration-300 ease-in-out group-hover:scale-110" />

						{/* Icon */}
						<div className="fw-icon absolute left-1/2 top-1/2 ml-1 w-7 -translate-x-1/2 -translate-y-1/2 transform">
							<Icon type={IconType.Play} />
						</div>
					</div>
				</div>

				{caption && <div className="pt-6 opacity-50">{caption}</div>}
			</div>

			{/* Modal */}
			<ModalVideo channel="custom" autoplay={1} isOpen={isModalOpen} url={video?.localFile?.publicURL} onClose={() => setIsModalOpen(false)} />
		</Section>
	);
}

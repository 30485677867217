import React from "react";
import { Link } from "gatsby";
import useCategories from "~/hooks/useCategories";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { WpPage_Page_FlexibleContent_FeaturedResources } from "~/_generated/types";
import { Icon, IconType } from "~/components/elements/Icon";
import ArticlesPreviewsSection from "./Resources/ArticlesPreviewsSection";

export default function FeaturedResources({ heading, content, resources, section, quicklinks }: WpPage_Page_FlexibleContent_FeaturedResources) {
	FeaturedResources;

	const { nodes } = useCategories();
	const categories = nodes;

	quicklinks = false;

	return (
		<Section {...section}>
			<div className="container">
				<div className="max-w-[520px]">
					<Heading as="h3" size={HeadingSizeOption.h3} text={heading} />

					<div className="mt-[12px]">
						<BodyCopy size={BodyCopySizeOption.Large} text={content} className="text-20" />
					</div>
				</div>

				<ArticlesPreviewsSection posts={resources} />

				<div className="mt-5 md:mt-[60px]">
					<div className="h-[1px] w-full bg-purple-2"></div>
				</div>

				{quicklinks && categories && (
					<div className="mt-5 md:mt-[70px]">
						<QuickLinks quicklinks={quicklinks} categories={categories} />
					</div>
				)}
			</div>
		</Section>
	);
}

export const QuickLinks = ({ quicklinks, categories }) => {
	return (
		<div className="w-full rounded-[10px] bg-purple-2">
			<div className="items-center justify-between pb-3 pt-[0.55rem] text-center md:flex md:space-y-0 md:px-[42px] md:py-[37px] md:pt-[37px] md:text-start">
				<div className="hidden font-heading text-[26px] leading-[33px] md:block">{quicklinks.heading}</div>
				<div className="hidden flex-wrap items-center justify-center space-x-[10px] md:flex lg:space-x-[48px]">
					{categories?.map((category, i) => (
						<Link key={`ql${i}`} to={category.uri} className="transition-colors duration-200 ease-in-out hover:text-green-2">
							<div className="flex items-center space-x-2">
								<div className={`fw-icon w-3 ${category?.category?.icon === "video" && "!w-4"}`}>
									<Icon type={category?.category?.icon} />
								</div>
								<span className="ml-[6px] block text-[18px] leading-4">{category.name}</span>
							</div>
						</Link>
					))}
				</div>

				<Button type={ButtonType.Text} link={quicklinks.link} icon={IconType.ArrowRightDown} />
			</div>
		</div>
	);
};

import React, { useRef, useState, useEffect } from "react";
import Note from "~/components/elements/Note";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { useMeasure, useWindowSize } from "react-use";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const DefaultBlocks = ({
	heading,
	content,
	blocks,
	// animation,
	// leftColAssetType
}) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const { height: windowHeight, width: windowWidth } = useWindowSize();
	const [measureRef, { height }] = useMeasure();
	const [stickyTop, setStickyTop] = useState(0);
	const [dynamicTop, setDynamicTop] = useState("0px");

	// Needed to avoid useMeasure height initialization issue
	useEffect(() => {
		setStickyTop(windowHeight / 2 - height / 2);
	}, [height, windowHeight]);

	// Needed to adjust top dynamically on window resize
	useEffect(() => {
		if (windowWidth > 1920) {
			if (dynamicTop !== `${stickyTop}px`) {
				setDynamicTop(`${stickyTop}px`);
			}
		} else {
			if (dynamicTop !== "7rem") {
				setDynamicTop("7rem");
			}
		}
	}, [windowWidth, stickyTop]);

	return (
		<div>
			{/* Heading, text */}
			<div className="container mb-12 md:mb-24">
				<div className="space-y-6 text-left md:text-center">
					{/* Heading */}
					<Heading as="h2" size={HeadingSizeOption.h2} text={heading} />

					{/* Text */}
					<div className="mx-auto max-w-[700px]">
						<BodyCopy size={BodyCopySizeOption.Large} text={content} />
					</div>
				</div>
			</div>

			<div className="relative grid gap-14 px-6 md:grid-cols-2 lg:gap-24 lg:px-0">
				{/* Asset col */}
				<div className="hidden w-full flex-auto md:block">
					{/* {leftColAssetType === "lottie" && <StepsAnimation animation={animation} />} */}
					<div
						ref={measureRef}
						className="sticky w-full max-w-[676px]"
						style={{
							top: dynamicTop,
							aspectRatio: "676/700",
						}}
					>
						<DefaultBlockImages blocks={blocks} activeIndex={activeIndex} />
					</div>
				</div>

				{/* Content col */}
				<div className="space-y-12 lg:mb-52 lg:space-y-32">
					{blocks?.length > 0 &&
						blocks?.map((block, i) => (
							<DefaultBlocksSection
								key={`default-blocks-section-${i}`}
								i={i}
								block={block}
								onInView={() => {
									setActiveIndex(i);
								}}
								// leftColAssetType={leftColAssetType}
							/>
						))}
				</div>
			</div>
		</div>
	);
};

const DefaultBlockImages = ({ blocks, activeIndex }) => {
	return blocks?.map((block, i) => {
		const isActive = activeIndex === i;

		return (
			<motion.div
				key={`default-blocks-image-${i}`}
				style={{
					zIndex: 10 - i,
				}}
				initial={{
					opacity: 0,
				}}
				animate={{
					opacity: isActive ? 1 : 0,
					transition: {
						duration: 0.4,
						ease: "easeInOut",
					},
				}}
				className="absolute inset-0 w-full"
			>
				<Image image={block?.image} key={`default-blocks-section-image-${i}`} className="h-full w-full" />
			</motion.div>
		);
	});
};

const DefaultBlocksSection = (props) => {
	const { i, block, onInView } = props;
	const [activeIndex, setActiveIndex] = useState(0);
	const [ref, isInView] = useInView({
		threshold: 0.7,
	});

	useEffect(() => {
		if (isInView) {
			onInView?.();
		}
	}, [isInView]);

	const intervalRef = useRef(null);

	const setIntervalCallback = () => {
		setActiveIndex((prev) => (prev + 1) % block?.accordionItems?.length);
	};

	// Update active index every 5 seconds
	useEffect(() => {
		intervalRef.current = setInterval(() => {
			setIntervalCallback();
		}, 5000);

		return () => clearInterval(intervalRef.current);
	}, []);

	const noteClickHandler = (i) => {
		setActiveIndex(i);
		clearInterval(intervalRef.current);

		intervalRef.current = setInterval(() => {
			setIntervalCallback();
		}, 5000);
	};

	return (
		<div
			ref={ref}
			className="mx-auto flex flex-col items-start justify-between border-b border-purple-2 pb-[2rem] pl-0 text-left md:max-w-[516px] lg:ml-0 lg:min-h-[600px] lg:border-b-0 lg:border-l lg:pb-0 lg:pl-12"
		>
			{/* Index */}
			<div
				style={{
					aspectRatio: "1/1",
				}}
				className="text-24 mb-6 flex items-center justify-center rounded-full bg-purple p-[10px] font-medium text-white lg:mb-[94px]"
			>
				{0 < 9 ? `0${i + 1}` : i + 1}
			</div>

			<div>
				{/* Heading */}
				<Heading as="h5" text={block.heading} classes="text-30 text-black mb-4" />

				{/* Text */}
				<div className="text-dark-purple/80">
					<div
						className="text-16 prose !font-normal !leading-[1.50]"
						dangerouslySetInnerHTML={{
							__html: block.content,
						}}
					/>
				</div>

				{/* Mobile image */}
				<div
					className="mx-auto mt-8 block w-full md:hidden"
					style={{
						aspectRatio: "676/700",
					}}
				>
					<Image image={block?.image} className="w-full" />
				</div>

				{/* Accordion items */}
				<div className="mt-8 flex flex-col gap-5">
					{block?.accordionItems?.length > 0 &&
						block?.accordionItems?.map((item, i) => (
							<button key={`default-blocks-section-accordion-item-${i}`} onClick={() => noteClickHandler(i)} className="relative block">
								<Note noMaxWidth {...item?.note} active={activeIndex === i} showChevron={block?.accordionItems?.length !== 1} />
							</button>
						))}
				</div>
			</div>
		</div>
	);
};

export default DefaultBlocks;

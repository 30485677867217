import React from "react";

import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import ButtonGroup from "~/components/elements/buttons/ButtonGroup";
import Note from "~/components/elements/Note";
import { WpPage_Page_FlexibleContent_TwoColumn } from "~/_generated/types";
import GridLines from "~/components/elements/GridLines";
import LottieAnimation from "~/components/elements/LottieAnimation";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useWindowSize } from "react-use";

const imageWidthMap = {
	"540px": "xl:max-w-[540px]",
	"630px": "xl:max-w-[630px]",
	"690px": "xl:max-w-[690px]",
};

const backgroundColorMap = {
	purple5: "bg-purple-5",
};

export default function TwoColumn(props: WpPage_Page_FlexibleContent_TwoColumn) {
	const {
		icon,
		number,
		subheading,
		heading,
		headingMaxWidth,
		content,
		links,
		image,
		section,
		reverse,
		imageWidth = "630px",
		addContentLeftBorder,
		imageBleed,
		note,
		addNote,
		mediaType,
		animation,
		backgroundColor = "none",
	} = props;
	const { width: windowWidth } = useWindowSize();
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.3,
	});

	const getHeadingMaxWidth = () => {
		if (windowWidth > 1024) {
			return headingMaxWidth || "100%";
		}

		return "100%";
	};

	return (
		<Section {...section}>
			<div className="overflow-hidden">
				{/* Grid lines */}
				<div className="tc-grid-columns">
					<GridLines colour="10" />
				</div>

				<div className={clsx("container", section.borderTop === "curved" && "md:pt-0")}>
					<div
						className={clsx(
							"flex flex-col-reverse items-center justify-between gap-10 md:gap-14 lg:gap-20",
							backgroundColor !== "none" && backgroundColorMap?.[backgroundColor],
							backgroundColor !== "none" && " md:pl-14 md:pr-14 xl:pl-20 xl:pr-0",
							reverse ? "md:flex-row-reverse" : "md:flex-row"
						)}
					>
						{/* Content */}
						<div
							className={clsx(
								backgroundColor !== "none" && "px-8 pb-8 md:p-0",
								addContentLeftBorder && "border-l border-purple-2 pl-8 md:pl-12",
								"flex flex-1 md:my-14 xl:my-20"
							)}
						>
							<div>
								<div className={clsx("text-width-class")}>
									<div className="max-w-[1092px] space-y-[26px]">
										{/* Icon */}
										{!!icon && (
											<div className="h-[73px] w-[73px]">
												<Image image={icon} />
											</div>
										)}

										{/* Number */}
										{!!number && (
											<div className="mb-10 text-purple">
												<Heading as="h5" size={HeadingSizeOption.h5} text={number} />
											</div>
										)}

										{/* Subheading */}
										{!!subheading && (
											<div className="subheading">
												<span
													dangerouslySetInnerHTML={{
														__html: subheading,
													}}
													className="text-16 text-purple-2"
												/>
											</div>
										)}

										{/* Heading */}
										{!!heading && (
											<div
												style={{
													maxWidth: getHeadingMaxWidth(),
												}}
												className="heading"
											>
												<Heading as="h3" size={HeadingSizeOption.h3} text={heading} />
											</div>
										)}

										{/* Text */}
										<div className="w-full">
											{content && (
												<div
													dangerouslySetInnerHTML={{
														__html: content,
													}}
													className="text-18 text-dark-purple/80"
												/>
											)}
										</div>
									</div>

									{/* Links */}
									{links && (
										<div className="mt-[27px]">
											<ButtonGroup links={links} />
										</div>
									)}

									{/* Note */}
									{addNote === "Yes" && note && (
										<div className="mt-8 md:mt-[50px]">
											<Note {...note} noMaxWidth />
										</div>
									)}
								</div>
							</div>
							{/* </Border> */}
						</div>

						{/* Asset */}
						<div className={clsx("relative w-full md:max-w-[50%]", backgroundColor !== "none" && "xl:!max-w-[575px]", imageWidthMap?.[imageWidth])}>
							{/* Animation, image */}
							<div
								ref={ref}
								className={clsx(
									"two-col-img relative w-full",
									imageBleed && reverse && "md:left-[-18%] md:w-[110%] lg:left-[-25%] lg:w-[108%] xl:left-[-22.5%]",
									imageBleed && !reverse && "md:w-[120%] lg:left-[10%] xl:left-[6.5%]"
								)}
							>
								{mediaType === "animation" ? (
									<>{animation && <LottieAnimation loop={false} play={inView} lazyData={animation} />}</>
								) : (
									<>{image && <Image image={image} className="w-full object-cover object-center" />}</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Section>
	);
}

const cheerio = require("cheerio");
const fetch = require("node-fetch");
export default async function () {
	const jobReq = await fetch("https://prevalent.bamboohr.com/jobs/embed2.php");
	const body = await jobReq.text();
	const $ = cheerio.load(body);
	const $deps = $(".BambooHR-ATS-Department-Item");
	const mappedJobs = $deps
		.map((i, dep) => {
			const $dep = $(dep);
			const $jobs = $dep
				.find(".BambooHR-ATS-Jobs-Item")
				.map((index, job) => {
					const $job = $(job);
					return {
						title: $job.find("a").text().trim(),
						link: $job.find("a").attr("href"),
						location: $job.find(".BambooHR-ATS-Location").text().trim(),
					};
				})
				.get();
			return {
				department: $dep.find(".BambooHR-ATS-Department-Header").text().trim(),
				jobs: $jobs,
			};
		})
		.get();
	return mappedJobs;
}

import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, FreeMode } from "swiper";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import { Button } from "~/components/elements/buttons/Button";
import CustomCursor from "~/components/elements/CustomCursor";

import { WpPage_Page_FlexibleContent_ImageSlider } from "~/_generated/types";

export default function ImageSlider({ layout, heading, content, link, images, section }: WpPage_Page_FlexibleContent_ImageSlider) {
	return (
		<Section {...section}>
			<div className="container space-y-12 lg:space-y-20">
				{layout === "2" ? <HeaderAlt heading={heading} content={content} /> : <Header heading={heading} content={content} link={link} />}
				<CustomCursor bgColor={section.backgroundColour === "dark-purple" ? "white" : section.backgroundColour === "white" && "black"}>
					<Swiper
						modules={[Scrollbar, A11y, FreeMode]}
						className="swiper-with-scrollbar relative flex w-full flex-col"
						scrollbar={{ draggable: true }}
						slidesPerView={`auto`}
						freeMode={true}
						grabCursor={true}
						style={{ overflow: `visible` }}
					>
						{images.map((item, i) => (
							<SwiperSlide
								className={`mr-8 flex max-w-[80%] flex-col justify-start last:mr-0 sm:max-w-[50%] lg:max-w-[35%]`}
								key={`hpswiper${i}`}
								style={{ height: "auto" }}
							>
								<div className="pointer-events-none text-center">
									<Image image={item.image} loading="eager" />
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</CustomCursor>
			</div>
		</Section>
	);
}

export const Header = ({ heading, content, link }) => {
	return (
		<div className="grid space-y-6 md:items-end lg:grid-cols-3 lg:space-y-0">
			<div className="border-padding-left-large max-w-[520px] space-y-3 border-l border-l-purple lg:col-span-2">
				<Heading type="h3" size={HeadingSizeOption.h3} text={heading} />
				<BodyCopy size={BodyCopySizeOption.Large} text={content} />
			</div>
			<div className="mb-5 flex items-center space-x-6 lg:justify-end">
				<span className="hidden text-xl md:block">{link.label}</span>
				<Button link={link.linkLink} icon={link.linkIcon} type={link.linkType} hoverBackgroundColor={link.linkHoverBackgroundColor} />
			</div>
		</div>
	);
};

export const HeaderAlt = ({ heading, content }) => {
	return (
		<div className="mx-auto max-w-[1010px] text-center">
			<Heading type="h2" size={HeadingSizeOption.h1} text={heading} />
		</div>
	);
};

import React from "react";
import { motion, useViewportScroll } from "framer-motion";

export const WigglyLine = ({ width }) => {
	return (
		<div className="flex justify-center">
			<svg width="1453" height="107" viewBox="0 0 1400 107" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M1444 104.674C1415.9 104.674 1415.9 2.84741 1388.14 2.84741C1360.04 2.84741 1360.04 104.674 1332.27 104.674C1304.18 104.674 1304.18 2.84741 1276.41 2.84741C1248.32 2.84741 1248.32 104.674 1220.55 104.674C1192.46 104.674 1192.46 2.84741 1164.69 2.84741C1136.59 2.84741 1136.59 104.674 1108.83 104.674C1080.73 104.674 1080.73 2.84741 1052.96 2.84741C1024.87 2.84741 1024.87 104.674 997.101 104.674C969.007 104.674 969.007 2.84741 941.239 2.84741C913.144 2.84741 913.144 104.674 885.377 104.674C857.282 104.674 857.282 2.84741 829.514 2.84741C801.42 2.84741 801.42 104.674 773.652 104.674C745.557 104.674 745.558 2.84741 717.79 2.84741C689.695 2.84741 689.695 104.674 661.927 104.674C633.833 104.674 633.833 2.84741 606.065 2.84741C577.971 2.84741 577.971 104.674 550.203 104.674C522.108 104.674 522.108 2.84741 494.341 2.84741C466.246 2.84741 466.246 104.674 438.478 104.674C410.384 104.674 410.384 2.84741 382.616 2.84741C354.522 2.84741 354.522 104.674 326.754 104.674C298.659 104.674 298.659 2.84741 270.891 2.84741C242.797 2.84741 242.797 104.674 215.029 104.674C186.935 104.674 186.935 2.84741 159.167 2.84741C131.072 2.84741 131.072 104.674 103.305 104.674C75.2102 104.674 75.2102 2.84741 47.4424 2.84741C19.3479 2.84741 19.3479 104.674 -8.41992 104.674"
					stroke="#6360D8"
					strokeOpacity="0.2"
					strokeWidth="4"
					strokeMiterlimit="10"
					strokeDasharray="14.27 10.7"
				/>
				<mask id="mask0_121:611" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="-9" y="0" width="1453" height="107">
					<path
						d="M1444 104.674C1415.9 104.674 1415.9 2.84741 1388.14 2.84741C1360.04 2.84741 1360.04 104.674 1332.27 104.674C1304.18 104.674 1304.18 2.84741 1276.41 2.84741C1248.32 2.84741 1248.32 104.674 1220.55 104.674C1192.46 104.674 1192.46 2.84741 1164.69 2.84741C1136.59 2.84741 1136.59 104.674 1108.83 104.674C1080.73 104.674 1080.73 2.84741 1052.96 2.84741C1024.87 2.84741 1024.87 104.674 997.101 104.674C969.007 104.674 969.007 2.84741 941.239 2.84741C913.144 2.84741 913.144 104.674 885.377 104.674C857.282 104.674 857.282 2.84741 829.514 2.84741C801.42 2.84741 801.42 104.674 773.652 104.674C745.557 104.674 745.558 2.84741 717.79 2.84741C689.695 2.84741 689.695 104.674 661.927 104.674C633.833 104.674 633.833 2.84741 606.065 2.84741C577.971 2.84741 577.971 104.674 550.203 104.674C522.108 104.674 522.108 2.84741 494.341 2.84741C466.246 2.84741 466.246 104.674 438.478 104.674C410.384 104.674 410.384 2.84741 382.616 2.84741C354.522 2.84741 354.522 104.674 326.754 104.674C298.659 104.674 298.659 2.84741 270.891 2.84741C242.797 2.84741 242.797 104.674 215.029 104.674C186.935 104.674 186.935 2.84741 159.167 2.84741C131.072 2.84741 131.072 104.674 103.305 104.674C75.2102 104.674 75.2102 2.84741 47.4424 2.84741C19.3479 2.84741 19.3479 104.674 -8.41992 104.674"
						stroke="black"
						strokeWidth="4"
						strokeMiterlimit="10"
					/>
				</mask>
				<g mask="url(#mask0_121:611)">
					<rect x="-115" y="-95.8032" style={{ width: `${width}%` }} height="284" fill="#6360D8" />
				</g>
			</svg>
		</div>
	);
};

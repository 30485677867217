import React from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import GridLines from "~/components/elements/GridLines";
import { Subheading } from "~/components/elements/typography/Subheading";

export default function AboutHero({ subheadingIcon, subheading, heading, about, image, links }) {
	return (
		<>
			<div className="about-hero">
				<GridLines colour="8" />
				<div className="container">
					<div className="grid grid-cols-8 border-l-2 border-l-purple md:grid-cols-12">
						<div className="col-span-7 col-start-2 mb-12 mt-[18px] md:col-span-11 md:col-start-2 lg:col-span-2 lg:col-start-2 lg:mb-0">
							<Subheading text={subheading} icon={subheadingIcon} />
						</div>

						<div className="col-span-8 col-start-2 max-w-[744px] md:col-span-12 md:col-start-2 lg:col-span-8">
							<Heading as="h1" size={HeadingSizeOption.h1Large} text={heading} />
						</div>
					</div>
				</div>
				<div className="container mt-12 grid w-full grid-cols-8 md:grid-cols-12 lg:mt-20">
					<div className="col-span-7 col-start-2 md:col-span-11">
						<Image
							image={image}
							className="ml-auto mr-[-20%] h-full transform transform-gpu overflow-hidden rounded-l-xl md:rounded-l-[40px] lg:w-[120%] xxl:mr-[-30%] xxl:w-[130%]"
						/>
					</div>
				</div>

				<div className="container mt-12 grid grid-cols-8 md:grid-cols-12 lg:mt-[71px]">
					<div className="col-span-7 col-start-2 md:col-span-11 md:col-start-2">
						<Heading as="h4" size={HeadingSizeOption.h4} text={about?.heading} />

						<div className="mt-[24px] space-y-[10px] md:flex md:space-x-[42px] md:space-y-0">
							<div className="max-w-[459px]">
								<BodyCopy text={about?.column1} size={BodyCopySizeOption.Large} />
							</div>
							<div className="max-w-[459px]">
								<BodyCopy text={about?.column2} size={BodyCopySizeOption.Large} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

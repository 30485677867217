import React, { useEffect, useState } from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import { WpPage_Page_FlexibleContent_Careers, WpPage_Page_FlexibleContent_Careers_Cta } from "src/_generated/types";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { Icon, IconType } from "~/components/elements/Icon";
import { AnimatePresence, motion } from "framer-motion";
import getJobs from "../utils/getJobs";

export default function Careers({ heading, content, section, cta }: WpPage_Page_FlexibleContent_Careers) {
	const [jobList, setJobList] = useState([]);

	useEffect(async () => {
		const jobs = await getJobs();
		setJobList(jobs);
	}, []);

	return (
		<Section {...section}>
			<div className="container">
				<div className="mx-auto max-w-[730px] space-y-7 text-center">
					<Heading as="h2" size={HeadingSizeOption.h2} text={heading} />
					{content && <BodyCopy text={content} />}
				</div>
				<div className="my-14 space-y-[27px] md:my-20">
					{jobList.map((job, i) => (
						<JobListings key={`jobGroup${i}`} {...job} />
					))}
				</div>

				<Cta {...cta} />
			</div>
		</Section>
	);
}

export const Cta = ({ heading, content, image, link }: WpPage_Page_FlexibleContent_Careers_Cta) => (
	<div className="flex flex-col items-center space-y-8 rounded-[10px] bg-dark-purple px-6 pt-12 md:px-12 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:px-0 lg:pt-0">
		<div className="w-full lg:w-[520px] lg:py-12 lg:pl-[75px]">
			<div className="flex flex-col items-center gap-4 border-purple-2 text-center md:gap-2 lg:items-start lg:border-l lg:pl-8 lg:text-left">
				<Heading as="h4" size={HeadingSizeOption.h4} text={heading} classes="text-30 max-w-[80%] md:max-w-full" />
				{!!content && <BodyCopy text={content} size={BodyCopySizeOption.Large} className="max-w-[70%] md:max-w-full" />}
			</div>
		</div>

		<div className="order-10 w-full max-w-[70%] self-center sm:max-w-[60%] md:max-w-[244px] lg:order-[0] lg:!mt-auto lg:ml-0">
			<Image image={image} />
		</div>

		<div className="lg:pr-12">
			<Button type={link?.linkType} link={link?.linkLink} icon={link?.linkIcon} />
		</div>
	</div>
);

export const JobListings = ({ department, jobs }) => {
	const [showJobs, setShowJobs] = useState(false);
	return (
		<div>
			<div onClick={() => setShowJobs(!showJobs)} className="group flex w-full cursor-pointer items-center justify-between">
				<h4 className="text-h4 !text-[24px] text-purple-2 md:!text-[30px]">{department}</h4>
				<div className={`transition-all duration-200 ease-in-out group-hover:opacity-20 ${showJobs ? "rotate-45" : "rotate-0"}`}>
					<Icon type={IconType.Faq} />
				</div>
			</div>
			<AnimatePresence>
				{!showJobs && <div className="mt-[30px] h-[1px] w-full bg-purple-2"></div>}
				{showJobs && jobs?.map((job, i) => <JobListing key={`job${i}`} {...job} i={i} />)}
			</AnimatePresence>
		</div>
	);
};

export const JobListing = ({ title, location, contract, link, i }) => {
	const [onHover, setOnHover] = useState(false);

	return (
		<motion.div
			className={`${i === 0 && "md:mt-12"}`}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.3 }}
			onMouseEnter={() => setOnHover(true)}
			onMouseLeave={() => setOnHover(false)}
		>
			<a
				href={link}
				target="_blank"
				className="group block w-full items-center justify-between border-b border-dark-purple border-opacity-20 bg-white py-4 transition-colors duration-200 ease-in-out hover:bg-[#F9F9FF] md:flex md:p-[30px] md:py-[30px]"
			>
				<div>
					<h5 className="text-h5 leading-[0.95]">{title}</h5>
					<div className="mt-[10px] flex space-x-[11px] text-[16px] leading-[1.563] tracking-[-0.02em] opacity-60 transition-opacity duration-200 group-hover:opacity-100">
						<div className="flex items-start space-x-[9px]">
							<div className="mt-[5px]">
								<Icon type={IconType.Location} />
							</div>
							<span>{location}</span>{" "}
						</div>
						{/* <div className="flex items-start space-x-[9px]">
              <div className="h-[10px] w-[10px] mt-[6px]">
                <Icon type={IconType.Clock} />
              </div>
              <span>{contract}</span>
            </div> */}
					</div>
				</div>
				<div className={`mt-6 transition-opacity duration-200 ease-in-out md:mt-0`}>
					<div className="pointer-events-none">
						<Button className="group-hover:bg-black" type={ButtonType.Button} icon={IconType.ArrowRightDown} link={{ title: "Apply Now", url: "#" }} />
					</div>
				</div>
			</a>
		</motion.div>
	);
};

import { m, LazyMotion, domAnimation } from "framer-motion";
import React, { useState } from "react";
import { Icon, IconType } from "./Icon";

export default function Switcher({ label1, label2, active, setActive, light = false, darkPurpleDot = false, showIcons = false, labelOpacity = false }) {
	return (
		<div className="flex cursor-pointer items-center space-x-3" onClick={() => setActive(!active)}>
			<div
				className={`transition-opacity duration-300 ease-in-out ${labelOpacity ? (!active ? "opacity-100" : "opacity-20 hover:opacity-60") : "opacity-100"}`}
			>
				{label1}
			</div>
			<LazyMotion features={domAnimation}>
				<m.div
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					transition={{ duration: 0.3 }}
					className={`relative h-6 w-12 rounded-[46px] ${light ? "bg-black" : "bg-white"} ${active && light ? "bg-green-2" : active && !light && "bg-purple"} `}
				>
					{showIcons && (
						<>
							<div
								className={`fw-icon absolute left-1.5 top-1/2 h-3.5 w-3.5 -translate-y-1/2 transform text-white transition-opacity duration-300 ease-in-out ${
									light ? "opacity-100" : "opacity-0"
								}`}
							>
								<Icon type={IconType.Day} />
							</div>
							<div
								className={`fw-icon absolute right-1.5 top-1/2 h-3.5 w-3.5 -translate-y-1/2 transform text-dark-purple transition-opacity duration-300 ease-in-out ${
									!light ? "opacity-100" : "opacity-0"
								}`}
							>
								<Icon type={IconType.Night} />
							</div>
						</>
					)}
					<div
						className={`h-4 w-4 ${light ? "bg-white" : darkPurpleDot ? "bg-dark-purple" : "bg-purple"} ${
							active && !light && "!bg-white"
						} absolute top-1/2 -translate-y-1/2 transform rounded-full transition-all duration-300 ease-in-out ${
							active ? "translate-x-[calc(100%+11px)]" : "translate-x-1"
						}`}
					/>
				</m.div>
			</LazyMotion>
			<div className={`transition-opacity duration-300 ease-in-out ${labelOpacity ? (active ? "opacity-100" : "opacity-20 hover:opacity-60") : "opacity-100"}`}>
				{label2}
			</div>

			<input type="checkbox" className="hidden" value={active} />
		</div>
	);
}

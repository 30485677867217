import React from "react";
import { FlexibleLayout } from "./flexible";

function removeLayoutNamePrefix(prefix, name) {
	return name.replace(prefix, "");
}

export const FlexibleContent = ({ prefix = "Page_Page_FlexibleContent_", content }) => {
	if (!content) return null;

	return content.map(({ fieldGroupName, ...layoutProps }, index) => {
		if (!fieldGroupName) {
			console.log(`Flexible layout fieldName not found, please configure graphql for components`);
			return null;
		}
		const layoutName = removeLayoutNamePrefix(prefix, fieldGroupName);
		const isSectionHidden = layoutProps?.section?.hideSection;

		if (layoutProps.section) {
			// Fill in some useful data for section rendering
			const previousLayout = content[index - 1];
			const nextLayout = content[index + 1];

			layoutProps.section.isFirst = index === 0;
			layoutProps.section.isLast = index === content.length - 1;
			layoutProps.section.previousSection = previousLayout && previousLayout.section ? previousLayout.section : null;
			layoutProps.section.nextSection = nextLayout && nextLayout.section ? nextLayout.section : null;
			layoutProps.section.layoutName = layoutName;
		}

		// This is some custom stuff to deal with contentslider needing to wrap it's neighbour
		// if (layoutProps.section.previousSection && layoutProps.section.layoutName === "ContentSlider") {
		// 	return (
		// 		<FlexibleLayout
		// 			key={index}
		// 			layoutName={layoutName}
		// 			nextLayout={() => {
		// 				const nextLayout = content[index + 1];
		// 				return <FlexibleLayout key={index + 1} layoutName={removeLayoutNamePrefix(prefix, nextLayout.fieldGroupName)} {...nextLayout} />;
		// 			}}
		// 			{...layoutProps}
		// 		/>
		// 	);
		// }

		// If previous layout is contentslider we ignore this as we pass that layout to contentslider itself
		// if (layoutProps.section.previousSection && layoutProps.section.previousSection.layoutName === "ContentSlider") {
		// 	return null;
		// }

		return isSectionHidden ? null : <FlexibleLayout key={index} layoutName={layoutName} {...layoutProps} />;
	});
};

export default FlexibleContent;

import React from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import { WpPage_Page_FlexibleContent_Usps, WpPage_Page_FlexibleContent_Usps_Usps } from "src/_generated/types";
import { useWindowSize } from "react-use";
import { Icon, IconType } from "~/components/elements/Icon";
import LottieAnimation from "~/components/elements/LottieAnimation";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";

export const UspsItemCentered = ({ heading, image, content }: WpPage_Page_FlexibleContent_Usps_Usps) => {
	const { width } = useWindowSize();

	return (
		<div className={clsx("flex h-full w-full flex-col items-center justify-center p-12 text-center md:px-8 md:py-0 lg:px-12")}>
			{/* Image */}
			<Image className="mb-[25px] w-full max-w-[82px] md:mb-[35px] md:max-w-[103px]" image={image} />
			{/* </div> */}

			<div className="mx-auto max-w-[342px] md:pb-[10px]">
				<div className="mx-auto w-full md:max-w-[83%]">
					<Heading as="h5" size={HeadingSizeOption.h5} text={heading} />
				</div>

				<div className="mt-[30px]">
					<BodyCopy text={content} size={BodyCopySizeOption.Default} />
				</div>
			</div>
		</div>
	);
};

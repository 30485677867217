import { useField } from "formik";
import React from "react";

export default function InputField(props) {
	const { label, ...other } = props;
	const [field, meta, helpers] = useField(props);
	const isInvalid = meta.touched && meta.error;

	const labelClassName = props.labelClassName;

	return (
		<label className={`flex flex-auto flex-col items-start text-black ${props.className || ""}`}>
			<div className={`${labelClassName} mb-[7px] text-[16px] font-medium leading-[1.65]`}>{label}</div>
			<textarea
				className={`w-full rounded-[6px] border border-black/20 bg-transparent px-4 py-2 text-black ${isInvalid && "border border-[red]"}`}
				{...field}
				{...other}
			/>

			{isInvalid && <div className="text-[13px] text-[red]">Required</div>}
		</label>
	);
}

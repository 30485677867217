import React, { useMemo } from "react";
import USPs from "./USPs/USPs";
import Hero from "./Hero/Hero";
import TwoColumn from "./TwoColumn";
import BlockLinks from "./BlockLinks";
import FeaturedResources from "./FeaturedResources";
import TabbedSlider from "./TabbedSlider";
import Marquee from "./Marquee";
import TwoColumnBlocks from "./TwoColumnBlocks";
import ContentSlider from "./ContentSlider/ContentSlider";
import TwoColumnAccordion from "./TwoColumnAccordion";
import PaginationSlider from "./PaginationSlider";
import TwoColumnTextWImage from "./TwoColumnTextWImage";
import TabbedSliderAlt from "./TabbedSliderAlt";
import Team from "./Team";
import WhatWeDo from "./WhatWeDo";
import ImageSlider from "./ImageSlider";
import FwTextWImage from "./FwTextWImage";
import MsfForm from "./MsfForm";
import Newsletter from "./Newsletter";
import Resources from "./Resources/Resources";
import ContactForm from "./ContactForm";
import Careers from "./Careers";
import Text from "./Text";
import Image from "./Image";
import Video from "./Video";
import Blockquote from "./Blockquote";
import AnimatingTwoColumnBlocks from "./AnimatingTwoColumnBlocks/AnimatingTwoColumnBlocks";
import ScrollingTwoColumnBlocks from "./ScrollingTwoColumnBlocks";
import ContactGrid from "./ContactGrid";
import FeaturedCaseStudies from "./FeaturedCaseStudies/FeaturedCaseStudies";

export const FlexibleLayout = (props: { layoutName: String }) => {
	const { layoutName } = props;

	// We 'as any' these but we could grab the types from the generated files;
	const layout = useMemo(() => {
		switch (layoutName) {
			case "Hero":
				return <Hero {...(props as any)} />;
			case "Usps":
				return <USPs {...(props as any)} />;
			case "TwoColumn":
				return <TwoColumn {...(props as any)} />;
			case "BlockLinks":
				return <BlockLinks {...(props as any)} />;
			case "FeaturedResources":
				return <FeaturedResources {...(props as any)} />;
			case "TabbedSlider":
				return <TabbedSlider {...(props as any)} />;
			case "Marquee":
				return <Marquee {...(props as any)} />;
			case "TwoColumnBlocks":
				return <TwoColumnBlocks {...(props as any)} />;
			case "ContentSlider":
				return <ContentSlider {...(props as any)} />;
			case "TwoColumnAccordion":
				return <TwoColumnAccordion {...(props as any)} />;
			case "PaginationSlider":
				return <PaginationSlider {...(props as any)} />;
			case "TwoColumnTextWImage":
				return <TwoColumnTextWImage {...(props as any)} />;
			case "TabbedSliderAlt":
				return <TabbedSliderAlt {...(props as any)} />;
			case "Team":
				return <Team {...(props as any)} />;
			case "WhatWeDo":
				return <WhatWeDo {...(props as any)} />;
			case "ImageSlider":
				return <ImageSlider {...(props as any)} />;
			case "FwTextWImage":
				return <FwTextWImage {...(props as any)} />;
			case "MsfForm":
				return <MsfForm {...(props as any)} />;
			case "Newsletter":
				return <Newsletter {...(props as any)} />;
			case "Resources":
				return <Resources {...(props as any)} />;
			case "ContactForm":
				return <ContactForm {...(props as any)} />;
			case "Careers":
				return <Careers {...(props as any)} />;
			case "AnimatingTwoColumnBlocks":
				return <AnimatingTwoColumnBlocks {...(props as any)} />;
			case "ScrollingTwoColumnBlocks":
				return <ScrollingTwoColumnBlocks {...(props as any)} />;
			case "ContactGrid":
				return <ContactGrid {...(props as any)} />;
			case "FeaturedCaseStudies":
				return <FeaturedCaseStudies {...(props as any)} />;
			// Article flexible content
			case "Blockquote":
				return <Blockquote {...(props as any)} />;
			case "Image":
				return <Image {...(props as any)} />;
			case "Video":
				return <Video {...(props as any)} />;
			case "Text":
				return <Text {...(props as any)} />;
			default:
				console.log(`Flexible layout mapping not found: ${layoutName}`);
				return <p className="w-fit mx-auto mb-20">{`Flexible layout ${layoutName} not found`}</p>;
		}
	}, [layoutName]);

	return layout;
};

import React from "react";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import GridLines from "~/components/elements/GridLines";
import { Subheading } from "~/components/elements/typography/Subheading";
import Float from "~/components/animations/Float";

export default function MSFHero({ subheadingIcon, subheading, heading, image, topImage, bottomImage }) {
	return (
		<>
			<GridLines colour="35" />
			<div className="absolute bottom-0 left-0 top-[40px] w-full md:top-[60px]">
				<div className="container relative flex h-full items-center text-center">
					<Image image={image} className="mx-auto w-full max-w-[771px]" />
					<div className="absolute left-[50%] top-[50%] flex w-full translate-x-[-50%] translate-y-[-50%] items-center justify-center">
						<div className="mx-auto mt-[-20px] max-w-[955px] space-y-[25px] px-2 text-center">
							{subheading && (
								<div className="flex justify-center">
									<Subheading text={subheading} icon={subheadingIcon} />
								</div>
							)}
							<Heading as="h1" size={HeadingSizeOption.h1Large} text={heading} />
						</div>
					</div>

					<Float className="absolute right-12 top-[12rem] hidden w-full max-w-[210px] md:block">
						<Image image={topImage} className="h-full w-full max-w-[210px]" />
					</Float>
					<Float yStart={10} yEnd={-10} duration={5} className="absolute bottom-[8rem] left-6 hidden w-full max-w-[282px] md:block">
						<Image image={bottomImage} className="h-full w-full max-w-[282px]" />
					</Float>
				</div>
			</div>
		</>
	);
}

import React from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_TwoColumnBlocks } from "~/_generated/types";

export default function TwoColumnBlocks({ layout, heading, blocks, section }: WpPage_Page_FlexibleContent_TwoColumnBlocks) {
	return (
		<Section {...section}>
			<div className="container">
				<div className="md:rid-cols-2 grid grid-cols-1 gap-x-32">
					<div className="space-y-16">
						<Heading as="h2" size={HeadingSizeOption.h2} text={heading} />
						<div className="border-padding-left-large space-y-10 border-l border-l-purple">
							{blocks.map((block, i) => (
								<div className="space-y-4">
									<div className="text-purple">
										<Heading as="h5" size={HeadingSizeOption.h5} text={block.heading} />
									</div>
									<div className="prose">
										<BodyCopy text={block.content} size={BodyCopySizeOption.Large} />
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="space-y-10">
						{blocks?.length > 0 &&
							blocks?.map((block, i) => (
								<div className="rounded-[4px]" key={`two-column-blocks-${i}`}>
									<Image image={block.image} />
								</div>
							))}
					</div>
				</div>
			</div>
		</Section>
	);
}

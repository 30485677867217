import React from "react";

function GridLines({ colour = "8" }) {
	let colourClass = "grid-lines--8";

	switch (colour) {
		case "10":
			colourClass = "grid-lines--10";
			break;
		case "35":
			colourClass = "grid-lines--35";
			break;
		case "40":
			colourClass = "grid-lines--40";
			break;
		case "45":
			colourClass = "grid-lines--45";
			break;
		case "85":
			colourClass = "grid-lines--85";
			break;
		case "90":
			colourClass = "grid-lines--90";
			break;
	}

	return (
		<div className="pointer-events-none absolute inset-0">
			<div className="container h-full w-full overflow-hidden">
				<div className="relative h-full w-full">
					<div className={`grid-lines absolute inset-0 -top-full ${colourClass}`} />
				</div>
			</div>
		</div>
	);
}

export default GridLines;

import React from "react";
import { Section } from "~/components/elements/Section";
import Image from "~/components/elements/Image";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { IconType } from "~/components/elements/Icon";
import CaseStudyPreviewRow from "~/components/previews/CaseStudyPreviewRow";
import CaseStudyPreviewCard from "~/components/previews/CaseStudyPreviewCard";

const FeaturedCaseStudies = (props) => {
	const { section, topRow, columnFeaturedPosts, heading, topFeaturedPost } = props;

	return (
		<Section {...section}>
			<div className="container">
				<div className="featured-case-studies">
					<div className="flex flex-col gap-14 md:gap-20">
						{/* Top row */}
						<div className="flex flex-col gap-[60px] md:flex-row md:border-l md:border-purple-2 md:pl-10 lg:pl-12">
							{/* Content */}
							<div className="flex flex-col gap-8">
								{/* Heading */}
								{!!topRow?.heading && <h2 dangerouslySetInnerHTML={{ __html: topRow?.heading }} className="text-48" />}

								{/* Text */}
								{!!topRow?.text && <div dangerouslySetInnerHTML={{ __html: topRow?.text }} className="text-18 opacity-70" />}

								{/* Link */}
								{!!topRow?.link?.url && (
									<Button type={ButtonType.Text} icon={IconType.ArrowRightDown} link={{ title: topRow?.link?.title, url: topRow?.link?.url }} />
								)}
							</div>

							{/* Image */}
							{!!topRow?.image && (
								<div className="w-full flex-shrink-0 md:max-w-[40%] xl:max-w-[484px]">
									<Image image={topRow?.image} className="h-full w-full" />
								</div>
							)}
						</div>

						{/* Heading */}
						{!!heading && (
							<div dangerouslySetInnerHTML={{ __html: heading }} className="text-45 mx-auto w-full max-w-[870px] text-center font-heading !leading-[1.10]" />
						)}

						{/* Top preview row */}
						{!!topFeaturedPost?.[0] && <CaseStudyPreviewRow post={topFeaturedPost?.[0]} />}

						{/* Column previews */}
						{columnFeaturedPosts?.length > 0 && (
							<div className="grid grid-cols-1 gap-14 md:grid-cols-2 lg:gap-20">
								{columnFeaturedPosts?.map((post, index) => (
									<CaseStudyPreviewCard key={post?.databaseId} post={post} />
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</Section>
	);
};

export default FeaturedCaseStudies;

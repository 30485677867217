import React, { useEffect, useState, useRef } from "react";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_ScrollingTwoColumnBlocks } from "~/_generated/types";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";
import { useMeasure } from "react-use";

export default function ScrollingTwoColumnBlocks(props: WpPage_Page_FlexibleContent_ScrollingTwoColumnBlocks) {
	const { heading, blocks, section, image } = props;
	const [activeIndex, setActiveIndex] = useState(0);
	const contentRef = useRef();
	// const intervalRef = useRef();

	// const setIntervalCallback = () => {
	// 	setActiveIndex((prev) => (prev + 1) % blocks?.length);
	// };

	// useEffect(() => {
	// 	intervalRef.current = setInterval(() => {
	// 		setIntervalCallback();
	// 	}, 4000);

	// 	return () => clearInterval(intervalRef.current);
	// }, []);

	const itemClickHandler = (index) => {
		setActiveIndex(index);
		// clearInterval(intervalRef.current);

		// intervalRef.current = setInterval(() => {
		// 	setIntervalCallback();
		// }, 4000);
	};

	// useEffect(() => {
	// 	const images = document.querySelectorAll(".scrolling-image");

	// 	const onScroll = (e) => {
	// 		const scrollTop = e.target.documentElement.scrollTop;
	// 		const images = document.querySelectorAll(".scrolling-image");
	// 		const top = contentRef.current.getBoundingClientRect().top + 20;
	// 		if (!images) return;
	// 		let count = 0;
	// 		let active = 0;
	// 		let found = false;
	// 		for (let image of images) {
	// 			if (top > Math.abs(scrollTop - image.offsetTop)) {
	// 				active = count;
	// 				found = true;
	// 			}
	// 			count++;
	// 		}
	// 		if (found) {
	// 			setActive(active);
	// 		}
	// 	};
	// 	window.addEventListener("scroll", onScroll);

	// 	return () => window.removeEventListener("scroll", onScroll);
	// }, []);

	return (
		<Section {...section} classname="overflow-visible">
			<div className="container">
				<div className="mx-auto !max-w-[1077px]">
					<div className="hidden md:block">
						{/* Heading */}
						<span className="block w-full md:max-w-[450px]">
							<Heading as="h2" size={HeadingSizeOption.h2} text={heading} />
						</span>

						<div className="relative mt-20 flex h-full flex-col-reverse items-start gap-14 md:flex-row xl:mt-36 xl:gap-20">
							{/* Items */}
							<div className="sticky-text sticky space-y-16">
								{/* Items */}
								<div ref={contentRef} className="flex flex-col gap-8">
									{/* Left border */}
									<div className="absolute left-0 top-0 h-full w-[1px] bg-white/10" />

									{blocks?.length > 0 &&
										blocks?.map((block, i) => (
											<ListItem key={`scrolling-two-column-blocks-block-${i}`} block={block} isActive={activeIndex === i} onClick={() => itemClickHandler(i)} />
										))}
								</div>
							</div>

							{/* Image */}
							<Image image={image} className="w-full md:max-w-[40%] xl:max-w-[486px]" />
						</div>
					</div>

					{/* Mobile */}
					<div className="block md:hidden">
						<div className="space-y-12">
							{/* Heading */}
							<Heading as="h2" size={HeadingSizeOption.h2} text={heading} />

							{/* Image */}
							<Image image={image} className="w-full" />

							<div className="border-padding-left-large space-y-6 border-l border-l-purple">
								{blocks?.length > 0 &&
									blocks?.map((block, i) => (
										<div key={`scrolling-two-column-blocks-mobile-block-${i}`} className="space-y-8">
											{/* <div className="rounded-[4px]">
											<Image image={block.image} />
										</div> */}
											<div className="space-y-2">
												{/* Heading */}
												<div className="text-purple">
													<Heading as="h5" size={HeadingSizeOption.h5} text={block.heading} />
												</div>

												{/* Text */}
												<div className={`prose max-w-[385px] overflow-hidden opacity-70`}>
													<BodyCopy text={block.content} size={BodyCopySizeOption.Default} />
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Section>
	);
}

const ListItem = ({ block, isActive, onClick }) => {
	const [measureRef, { height }] = useMeasure();

	return (
		<motion.button
			style={{ height }}
			transition={{
				duration: 0.4,
				ease: "easeInOut",
			}}
			onClick={() => {
				onClick?.();
			}}
			className={clsx(
				"sticky-list-item",
				"pl-8 lg:pl-12",
				"border-l transition-opacity duration-300 ease-in-out",
				isActive ? "border-l-purple opacity-100" : "border-l-black opacity-[0.16]"
			)}
		>
			<div ref={measureRef} className="flex flex-col justify-start gap-5">
				{/* Heading */}
				<div className="text-purple">
					<Heading as="h5" size={HeadingSizeOption.h5} text={block.heading} classes="text-45 !leading-[0.80] text-left" />
				</div>

				<AnimatePresence>
					{isActive && (
						<motion.div
							className={`max-w-[385px] overflow-hidden`}
							initial={{ opacity: 0, y: 10 }}
							animate={{ opacity: 1, y: 0, transition: { duration: 0.4, ease: "easeInOut" } }}
						>
							<div dangerouslySetInnerHTML={{ __html: block.content }} className="text-16 text-left !font-normal !leading-[1.60] opacity-70" />
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</motion.button>
	);
};

export const Block = ({ image, i }) => {
	return (
		<div className="scrolling-image rounded-[4px]">
			<Image image={image} />
		</div>
	);
};

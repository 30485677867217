import clsx from "clsx";
import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

function Float({ children, duration = 3, aDelay, easing = [0.42, 0, 0.58, 1], className, yStart = -10, yEnd = 10 }) {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.3,
	});

	const controls = useAnimation();

	// const transition = useMemo(
	// 	() => ({
	// 		duration,
	// 		delay: aDelay,
	// 		ease: easing,
	// 	}),
	// 	[duration, aDelay, easing]
	// );

	const floatVariants = {
		initial: {
			y: yStart,
		},
		animate: {
			y: yEnd,
			transition: {
				type: "tween",
				repeat: Infinity,
				repeatType: "reverse",
				delay: aDelay,
				duration: duration,
			},
		},
	};

	useEffect(() => {
		if (inView) {
			controls.start("show");
		}
	}, [controls, inView]);

	return (
		<motion.div ref={ref} initial="initial" animate="animate" variants={floatVariants} className={clsx(className, "hero-float")}>
			{children}
		</motion.div>
	);
}

export default Float;

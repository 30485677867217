import React, { useLayoutEffect, useRef, useState } from "react";

import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import GridLines from "~/components/elements/GridLines";
import { Subheading, SubheadingIconOption } from "~/components/elements/typography/Subheading";
import CareersHero1 from "~/assets/images/careers-hero-1.png";
import CareersHero2 from "~/assets/images/careers-hero-2.png";
import CareersHero3 from "~/assets/images/careers-hero-3.png";
import CareersHero4 from "~/assets/images/careers-hero-4.png";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { useWindowSize } from "react-use";

export default function CareersHero({ subheadingIcon, subheading, heading, image }) {
	const { width } = useWindowSize();

	const ref = useRef();
	const { scrollY } = useViewportScroll();
	const [clientHeight, setClientHeight] = useState(0);
	const y = useTransform(scrollY, [0, clientHeight], [0, width < 768 ? -10 : -40]);
	const y2 = useTransform(scrollY, [0, clientHeight], [0, width < 768 ? -20 : -80]);
	const y3 = useTransform(scrollY, [0, clientHeight], [0, width < 768 ? -10 : -130]);
	const y4 = useTransform(scrollY, [0, clientHeight], [0, width < 768 ? -20 : -150]);

	const loaded = () => {
		setClientHeight(ref.current.clientHeight);
	};

	useLayoutEffect(() => {
		setClientHeight(ref.current.clientHeight);
	}, [scrollY]);

	return (
		<div ref={ref}>
			<GridLines colour="4" />
			<div className="relative">
				<div className="container">
					<div className="mx-auto max-w-[1075px] space-y-[20px] text-center md:space-y-[37px]">
						{subheading && (
							<div className="flex justify-center">
								<Subheading text={subheading} icon={subheadingIcon} />
							</div>
						)}
						<Heading as="h1" size={HeadingSizeOption.h1Large} text={heading} />
					</div>
				</div>
				<div className="relative -mt-10">
					<motion.img
						// style={{ y }}
						src={CareersHero1}
						loading="eager"
						onLoad={loaded}
						style={{
							y,
							aspectRatio: "1901/1090",
						}}
					/>
					<div className="absolute left-1/2 top-[11%] w-3/4 -translate-x-1/2 transform">
						<motion.img style={{ y: y2 }} src={CareersHero2} loading="eager" onLoad={loaded} />
					</div>
					<div className="absolute top-[0%]">
						<motion.img style={{ y: y3 }} src={CareersHero3} loading="eager" onLoad={loaded} />
					</div>
					<div className="absolute top-[3.5%] z-10">
						<div className="relative">
							<motion.img style={{ y: y4 }} src={CareersHero4} loading="eager" onLoad={loaded} />
							<motion.div style={{ y: y4 }} className="careers-hero-layer-4-inner" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import React, { useState, useEffect } from "react";
import { motion, useMotionValue, useSpring, AnimatePresence } from "framer-motion";
import { Icon } from "~/components/elements/Icon";
import clsx from "clsx";

export default function CustomCursor({ children, bgColor = "black", ...other }) {
	const cursorX = useMotionValue(-100);
	const cursorY = useMotionValue(-100);

	const springConfig = { damping: 50, stiffness: 700 };
	const cursorXSpring = useSpring(cursorX, springConfig);
	const cursorYSpring = useSpring(cursorY, springConfig);

	const [showCursor, setShowCursor] = useState(false);

	useEffect(() => {
		const moveCursor = (e) => {
			cursorX.set(e.clientX - 68);
			cursorY.set(e.clientY - 68);
		};

		window.addEventListener("mousemove", moveCursor);

		return () => {
			window.removeEventListener("mousemove", moveCursor);
		};
	}, []);

	return (
		<div className="custom-cursor relative" onMouseEnter={() => setShowCursor(true)} onMouseLeave={() => setShowCursor(false)} {...other}>
			{children}

			<AnimatePresence>
				{showCursor && (
					<motion.div
						initial={{ scale: 0.8, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						exit={{ opacity: 0, transition: { duration: 0.1 } }}
						className="cursor pointer-events-none"
						style={{
							translateX: cursorXSpring,
							translateY: cursorYSpring,
						}}
					>
						<div
							className={clsx(
								"text-h6 flex h-20 w-20 items-center justify-center rounded-full md:h-32 md:w-32",
								`bg-${bgColor}`,
								bgColor === "rose" && "text-navy",
								bgColor === "white" ? "bg-white text-black" : "text-white"
							)}
						>
							<Icon type="arrowRight" />
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}

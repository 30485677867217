import React, { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";
import { WpPage_Page_FlexibleContent_ContentSlider } from "~/_generated/types";
import { motion, useMotionValue, useTransform } from "framer-motion";
import ContentSliderMobile from "./ContentSliderMobile";
import ContentSliderSlide from "./ContentSliderSlide";

export default function ContentSlider({ heading, content, slides, section, nextLayout }: WpPage_Page_FlexibleContent_ContentSlider) {
	const [progress, setProgress] = useState(0);
	const [scrollY, setScrollY] = useState(0);

	const outerContainerRef = useRef();
	const containerRef = useRef();
	const slidesLength = slides.length;

	// Calculate cards width
	const cardRefs = useRef([]) as any;
	const [totalCardsWidth, setTotalCardsWidth] = useState(0);

	// Initialize cardRefs with refs for each card
	cardRefs.current = slides?.map((_: any, i: string | number) => cardRefs.current[i] || createRef());

	// Calculate total width of all cards by summing up the offsetWidth of each card
	useLayoutEffect(() => {
		const newTotalWidth = cardRefs.current.reduce(
			(sum: any, ref: { current: { offsetWidth: number } }) => sum + (ref.current ? ref.current.offsetWidth : 0),
			0
		);

		setTotalCardsWidth(newTotalWidth);
	}, []);

	// Setup window scroll listener
	useEffect(() => {
		const onScroll = (e) => {
			setScrollY(e.target.documentElement.scrollTop);
		};
		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	// Set progress logic
	useLayoutEffect(() => {
		const relevantHeight = outerContainerRef?.current.offsetHeight;
		const sectionY = outerContainerRef?.current.offsetTop;
		const sectionOverflow = scrollY - sectionY;

		let _progress = sectionOverflow / relevantHeight;

		if (_progress < 0) _progress = 0;
		setProgress(_progress);
	}, [slidesLength, outerContainerRef, scrollY]);

	// Slider transform logic
	const progressMotionValue = useMotionValue(progress);

	useEffect(() => {
		progressMotionValue.set(progress);
	}, [progress]);

	const containerWidth = containerRef?.current?.offsetWidth;
	const sliderXTransform = useTransform(progressMotionValue, [0, 0.7], ["0px", `-${totalCardsWidth - containerWidth}px`]);

	return (
		<>
			<div>
				<div
					data-layout="ContentSliderWrapper"
					className="content-slider relative hidden md:block"
					style={{ height: `${totalCardsWidth}px` }}
					ref={outerContainerRef}
				>
					<div className="sticky top-0 md:-top-20">
						<Section {...section} classname="static overflow-hidden">
							<div ref={containerRef} className="container">
								<div className="border-padding-left-large content-slider-border-fix space-y-24">
									{/* Top copy */}
									<div className="max-w-[580px] space-y-3">
										{/* Heading */}
										<div className="max-w-[350px]">
											<Heading type="h2" size={HeadingSizeOption.h2} text={heading} />
										</div>

										{/* Text */}
										<BodyCopy size={BodyCopySizeOption.Large} text={content} />
									</div>

									{/* Slider */}
									<motion.div className="content-slider-pane flex" style={{ x: sliderXTransform }}>
										{slides?.length > 0 &&
											slides?.map((slide, i) => <ContentSliderSlide key={`content-slider-slide-${i}`} cardRefs={cardRefs} slide={slide} i={i} />)}
									</motion.div>
								</div>
							</div>
						</Section>
					</div>
				</div>
			</div>

			{/* Mobile */}
			<div className="block md:hidden">
				<Section {...section}>
					<ContentSliderMobile slides={slides} heading={heading} content={content} />
				</Section>
			</div>

			{/* Why needed in component? */}
			{/* <div className="pt-16">{nextLayout()}</div> */}
		</>
	);
}

import React from "react";
import clsx from "clsx";
import Image from "~/components/elements/Image";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";

const ContentSliderMobile = ({ slides, heading, content }) => {
	return (
		<div className="container">
			<div className="space-y-3">
				<Heading type="h2" size={HeadingSizeOption.h2} text={heading} />
				<BodyCopy size={BodyCopySizeOption.Large} text={content} />
			</div>

			<div className="mt-12 space-y-12">
				{slides?.length > 0 &&
					slides?.map((slide, i) => (
						<div key={`content-slider-mobile-section-${i}`} className={clsx("flex h-auto w-full flex-col")}>
							<div className="space-y-6">
								<div className="rounded-[4px]">
									<Image image={slide.image} loading="eager" />
								</div>

								<div className="max-w-[600px] space-y-3">
									<Heading type="h4" size={HeadingSizeOption.h4} text={slide.heading} />
									<BodyCopy text={slide.content} />
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default ContentSliderMobile;

import React from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import ButtonGroup from "~/components/elements/buttons/ButtonGroup";
import { Subheading, SubheadingIconOption } from "~/components/elements/typography/Subheading";
import Float from "~/components/animations/Float";
import { useWindowSize } from "react-use";

export default function WhatWeDoHero({ subheading, subheadingIcon, heading, headingMaxWidth, content, image1, image2, links }) {
	const { width: windowWidth } = useWindowSize();

	const getHeadingMaxWidth = () => {
		if (windowWidth > 1024) {
			return headingMaxWidth || "100%";
		}

		return "100%";
	};

	return (
		<>
			{/* Grid lines */}
			{/* <GridLines colour="8" /> */}

			<div className="container overflow-hidden pt-8">
				<div className="relative">
					<div className="mx-auto max-w-[911px] space-y-[20px] text-center md:space-y-10">
						{/* Subheading */}
						{subheading && (
							<div className="flex justify-center">
								<Subheading text={subheading} icon={subheadingIcon} />
							</div>
						)}

						{/* Heading */}
						<div
							style={{
								maxWidth: getHeadingMaxWidth(),
							}}
							className="mx-auto"
						>
							<Heading as="h1" classes="!leading-[1.20]" size={HeadingSizeOption.h1} text={heading} />
						</div>

						{/* Body Text */}
						<div className="mx-auto max-w-[770px] opacity-50">
							<BodyCopy size={BodyCopySizeOption.Large} text={content} />
						</div>
					</div>

					{/* Links */}
					{links?.length > 0 && (
						<div className="mt-8 flex justify-center md:mt-[42px]">
							<ButtonGroup links={links} />
						</div>
					)}

					{/* Images */}
					<div className="mt-16 flex w-full justify-center md:mt-[70px]">
						{/* Image 1 */}
						<div className="h-full w-full max-w-[80%] lg:max-w-[904px]">
							<Image image={image1} />
						</div>

						{/* Image 2 */}
						{/* <Float yStart={10} yEnd={-10} duration={3} aDelay={2} className="hidden h-full w-full max-w-[486px] md:block">
							<Image image={image2} />
						</Float> */}
					</div>
				</div>
			</div>
		</>
	);
}

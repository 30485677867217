import React from "react";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { Subheading } from "~/components/elements/typography/Subheading";
import ContactHero1 from "~/assets/images/contact-hero-1.png";
import ContactHero2 from "~/assets/images/contact-hero-2.png";
import ContactHero3 from "~/assets/images/contact-hero-3.png";
import ScrollMove from "../../animations/ScrollMove";

export default function ContactHero({ subheadingIcon, subheading, heading, image, columns }) {
	return (
		<>
			<div className={`${!columns && "pb-[98px]x"}`}>
				<div className="container relative text-center">
					<div className="absolute left-[5vw] top-[10vw] hidden w-16 xl:block">
						<ScrollMove direction="y" offset={25}>
							<img src={ContactHero1} alt="" />
						</ScrollMove>
					</div>
					<div className="absolute right-0 top-[-0.5rem] hidden w-28 xl:block">
						<ScrollMove direction="y" offset={30}>
							<img src={ContactHero2} alt="" />
						</ScrollMove>
					</div>
					<div className="absolute right-0 top-[15vw] hidden w-24 xl:block">
						<ScrollMove direction="y" offset={20}>
							<img src={ContactHero3} alt="" />
						</ScrollMove>
					</div>
					<div className="relative mx-auto max-w-[871px] space-y-[25px] text-center">
						{subheading && (
							<div className="flex justify-center">
								<Subheading text={subheading} icon={subheadingIcon} />
							</div>
						)}
						<Heading as="h1" size={HeadingSizeOption.h1} text={heading} />
					</div>
					{columns && (
						<div className="mx-auto mt-[75px] max-w-[910px] text-left lg:px-0">
							<div className="ml-[-58px] flex flex-wrap justify-center">
								{columns?.map((col, i) => (
									<div key={`col${i}`} className={`flex w-full flex-auto flex-col pl-[58px] md:w-1/2 md:max-w-[354px] ${i === 1 && "text-underlines"}`}>
										<HeroColumn isLast={i === columns?.length - 1} {...col} />
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export const HeroColumn = ({ content, heading, isLast }) => {
	return (
		<div
			className={`pt-6 text-[18px] md:pt-0 ${
				isLast && "border-b-0 pb-6"
			} flex-auto border-b border-purple-1 leading-[22px] md:border-b-0 md:border-l md:pl-[19px]`}
		>
			{heading}

			<div className="contact-column py-6 text-[16px] leading-[1.65] tracking-[-0.01em] md:pb-[40px]" dangerouslySetInnerHTML={{ __html: content }} />
		</div>
	);
};

import React from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import GridLines from "~/components/elements/GridLines";
import ButtonGroup from "~/components/elements/buttons/ButtonGroup";
import Float from "~/components/animations/Float";
import HomeAstronaut from "~/assets/images/home-astronaut.png";
import HomeCaveman from "~/assets/images/home-caveman.png";
import HomeNewton from "~/assets/images/home-newton.png";

export default function HomeHero({ heading, content, image, links }) {
	return (
		<div>
			<GridLines colour="8" />

			<div className="container relative z-10 pt-4">
				<div className="mx-auto max-w-[1160px] space-y-[26px] text-center">
					<Heading as="h1" size={HeadingSizeOption.h1Large} text={heading} />
					<div className="mx-auto max-w-[558px]">
						<BodyCopy size={BodyCopySizeOption.Large} text={content} />
					</div>
				</div>
				<div className="mt-[32px] flex justify-center">
					<ButtonGroup links={links} className="!w-full justify-center" />
				</div>
			</div>
			<HomeHeroImages heroImage={image} />
		</div>
	);
}

export const HomeHeroImages = ({ heroImage }) => {
	return (
		<div className="rounded-b-[39px]x md:rounded-b-[99px]x relative overflow-hidden lg:-mt-48 xl:mt-[-264px]">
			<Image image={heroImage} className="h-auto w-full translate-y-[1px] transform transform-gpu" />
			<div className="absolute left-[7vw] top-[11vw] xxxl:left-[7%] xxxl:top-[16.5%]">
				<Float>
					<img src={HomeCaveman} className="w-[30vw] md:w-[17vw] xxxl:w-[17rem]" />
				</Float>
			</div>
			<div className="absolute left-[34vw] top-[20vw] hidden md:block xxxl:left-[34%] xxxl:top-[30%]">
				<Float aDelay="2">
					<img src={HomeNewton} className="md:w-[17vw] xxxl:w-[17rem]" />
				</Float>
			</div>
			<div className="absolute left-auto right-[5vw] top-[13vw] md:right-[15vw] xxxl:right-[15%] xxxl:top-[19.5%]">
				<Float aDelay="1.5">
					<img src={HomeAstronaut} className="w-[35vw] md:w-[22vw] xxxl:w-[22rem]" />
				</Float>
			</div>
		</div>
	);
};

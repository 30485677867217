import React from "react";
import { Section } from "~/components/elements/Section";
import { WpPost_Article_FlexibleContent_Image } from "src/_generated/types";
import ImageInner from "~/components/elements/Image";

export default function Image({ image, caption, section }: WpPost_Article_FlexibleContent_Image) {
	return (
		<Section {...section} paddingTop="none" paddingBottom="none">
			<div className="article-image flex w-full flex-col px-0">
				<ImageInner image={image} />
				{caption && <div className="pt-6 opacity-50">{caption}</div>}
			</div>
		</Section>
	);
}

import React from "react";
import { Section } from "~/components/elements/Section";
import { WpPage_Page_FlexibleContent_AnimatingTwoColumnBlocks } from "~/_generated/types";
import DefaultBlocks from "./DefaultBlocks";
import { AltBlocks } from "./AltBlocks";

export default function AnimatingTwoColumnBlocks({
	heading,
	content,
	blocks,
	animation1,
	layout,
	section,
	leftColAssetType,
}: WpPage_Page_FlexibleContent_AnimatingTwoColumnBlocks) {
	return (
		<Section {...section} classname="overflow-visible">
			{layout === "2" ? (
				<AltBlocks heading={heading} blocks={blocks} animation={animation1} />
			) : (
				<DefaultBlocks heading={heading} content={content} blocks={blocks} animation={animation1} leftColAssetType={leftColAssetType} />
			)}
		</Section>
	);
}

import React from "react";
import Image from "~/components/elements/Image";
import { BodyCopy } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";

const ContentSliderSlide = ({ cardRefs, slide, i }) => {
	return (
		<div ref={cardRefs.current[i]} key={`content-slider-slide-${i}`} className={`flex h-auto flex-shrink-0 transform flex-col pr-12 last:mr-0`}>
			<div className="w-full max-w-[550px] space-y-12">
				{/* Image */}
				<div
					style={{
						aspectRatio: "550/150",
					}}
					className="w-full max-w-[550px] rounded-[4px]"
				>
					<Image image={slide.image} loading="eager" objectFit="cover" className="h-full" />
				</div>

				{/* Text */}
				<div className="max-w-[600px] space-y-5">
					{/* Heading */}
					<Heading type="h4" size={HeadingSizeOption.h4} text={slide.heading} />

					{/* Text */}
					<BodyCopy text={slide.content} />
				</div>
			</div>
		</div>
	);
};

export default ContentSliderSlide;

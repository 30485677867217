import React from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import { WpPage_Page_FlexibleContent_Usps_Usps } from "src/_generated/types";
import clsx from "clsx";

const UspsItemLeftAligned = ({ heading, image, content, layout }: WpPage_Page_FlexibleContent_Usps_Usps) => (
	<div className="flex h-full w-full border-l border-purple-2 px-6 md:px-12">
		<div className="flex flex-col gap-6 md:gap-8">
			{/* Heading */}
			<Heading as="h4" size={HeadingSizeOption.h4} text={heading} />

			{/* Image */}
			<Image className={clsx("max-w-[82px] md:max-w-[103px]", layout === "1" && "sm:!mt-8 lg:!mt-[96px]", layout === "4" && "order-first")} image={image} />

			{/* Text */}
			<BodyCopy text={content} size={BodyCopySizeOption.Default} />
		</div>
	</div>
);

export default UspsItemLeftAligned;

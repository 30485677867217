import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import { Button, ButtonType } from "~/components/elements/buttons/Button";

import { WpPage_Page_FlexibleContent_BlockLinks, WpPage_Page_FlexibleContent_BlockLinks_Blocks } from "~/_generated/types";
import { IconType } from "~/components/elements/Icon";
import { Link } from "gatsby";

export default function BlockLinks({ blocks, heading, section, layout }: WpPage_Page_FlexibleContent_BlockLinks) {
	return (
		<Section {...section}>
			<div className="container space-y-12 md:space-y-20">
				{heading && (
					<div className="text-center">
						<Heading size={HeadingSizeOption.h2} as="h2" text={heading} />
					</div>
				)}
				{layout === "1" ? <List blocks={blocks} /> : layout === "2" ? <TwoColumns blocks={blocks} /> : null}
			</div>
		</Section>
	);
}

export const TwoColumns = ({ blocks }: WpPage_Page_FlexibleContent_BlockLinks_Blocks) => {
	return (
		<div className="grid grid-cols-1 gap-y-12 md:grid-cols-2 md:gap-x-5 md:gap-y-0">
			{blocks?.map((block, i) => (
				<ColourBlockAlt key={`blockLink${i}`} {...block} />
			))}
		</div>
	);
};

export const List = ({ blocks }: WpPage_Page_FlexibleContent_BlockLinks_Blocks) => {
	return (
		<div className={`space-y-7 md:space-y-[60px]`}>
			{blocks?.map((block, i) => (
				<ColourBlock key={`blockLink${i}`} {...block} />
			))}
		</div>
	);
};

export const ColourBlock = ({ heading, content, image, backgroundColor, page }: WpPage_Page_FlexibleContent_BlockLinks_Blocks) => {
	let bgColourClass = "";

	const block = useRef(null);

	switch (backgroundColor) {
		case "green":
			bgColourClass = "bg-green-6";
			break;
		case "orange":
			bgColourClass = "bg-orange-4";
			break;
		case "blue":
			bgColourClass = "bg-[#EDF6FF]";
		case "purple4":
			bgColourClass = "bg-purple-4";
			break;
	}

	return (
		<div className="pointer-events-none top-40 h-auto md:sticky">
			<div className="pointer-events-auto md:flex" ref={block}>
				<div className={`w-full md:w-1/2 ${bgColourClass} `}>
					<div className="flex h-full flex-col justify-between px-6 py-[33px] md:py-6 lg:p-20">
						<div>
							{/* Heading */}
							<div className="mb-[29px] md:max-w-[90%]">
								<Heading size={HeadingSizeOption.h3} as="h3" text={heading} />
							</div>

							{/* Text */}
							<div className="opacity-60">
								<BodyCopy size={BodyCopySizeOption.Large} text={content} />
							</div>
						</div>

						{/* Link */}
						<div className="mt-3 md:mt-[17px]">
							<Button type={ButtonType.Text} icon={IconType.ArrowRightDown} link={{ title: "Learn more", url: page[0]?.uri }} />
						</div>
					</div>
				</div>

				{/* Image */}
				<div className="aspect-[599/473] w-full md:max-w-[50%] lg:max-w-[599px]">
					<Image image={image} />
				</div>
			</div>
		</div>
	);
};

export const ColourBlockAlt = ({ heading, content, image, page }: WpPage_Page_FlexibleContent_BlockLinks_Blocks) => {
	return (
		<Link to={page[0]?.uri} className="group space-y-10">
			<div className="aspect-h-5 aspect-w-6 w-full overflow-hidden rounded-[10px]">
				<Image image={image} objectFit="cover" className="!absolute transform transition-transform duration-700 ease-in-out group-hover:scale-105" />
			</div>
			<div className={`w-full`}>
				<div className="space-y-6 md:space-y-12">
					<div className="space-y-6">
						<Heading size={HeadingSizeOption.h4} as="h4" text={heading} />
						<div className="max-w-[523px] text-white text-opacity-70">
							<BodyCopy size={BodyCopySizeOption.Large} text={content} />
						</div>
					</div>
					<div className="mt-[17px]">
						<Button noLink type={ButtonType.Text} icon={IconType.ArrowRightDown} link={{ title: "Learn more", url: page[0]?.uri }} />
					</div>
				</div>
			</div>
		</Link>
	);
};

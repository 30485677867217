import React from "react";
import { Icon, IconType } from "~/components/elements/Icon";

const Disclaimer = ({ disclaimer }) => (
	<div className="mt-[48px] flex items-center justify-center px-[24px] md:mt-[96px] md:px-[0]">
		<div className="h-[1px] flex-auto bg-black bg-opacity-[0.15]"></div>
		<div className="max-w-[940px] rounded-lg border border-black border-opacity-[0.15] bg-[#EEEEEE]">
			<div className="flex items-center px-[18px] py-[12px]">
				<div className="disclaimer flex items-start text-[14px] leading-[1.65] tracking-[-0.01em] md:text-left">
					<div className="w-4.5 h-4.5 fw-icon mr-2 mt-0.5 flex-shrink-0 text-purple">
						<Icon type={IconType.Warning} />
					</div>
					<div className="" dangerouslySetInnerHTML={{ __html: disclaimer }} />
				</div>
			</div>
		</div>
		<div className="h-[1px] flex-auto bg-black bg-opacity-[0.15]"></div>
	</div>
);

export default Disclaimer;

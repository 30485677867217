import React from "react";
import { Icon } from "~/components/elements/Icon";

export default function SocialLinks({ socialLinks }) {
	return (
		<div className="flex">
			{socialLinks.map((socialLink, i) => (
				<a key={i} href={socialLink.link.url} target="_blank" className="block h-auto w-3.5 transition-colors duration-200 ease-in-out hover:text-green-2">
					<Icon type={socialLink.icon} />
				</a>
			))}
		</div>
	);
}

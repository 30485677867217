import React, { Fragment } from "react";
import { Section } from "../elements/Section";
import { Button, ButtonType } from "../elements/buttons/Button";
import { IconType } from "../elements/Icon";
import clsx from "clsx";

const ContactGrid = (props) => {
	const { section, columns } = props;

	return (
		<div className="contact-grid">
			<Section {...section}>
				<div className="container">
					<div className="mx-auto grid max-w-[998px] grid-cols-1 md:grid-cols-3">
						{columns?.length > 0 &&
							columns?.map((col, index) => {
								return (
									<div key={`contact-grid-col-${index}`} className="border-b border-purple-2 py-8 md:border-b-0 md:border-l md:px-8 md:py-0">
										{/* Heading */}
										{col?.heading && <span className="text-18 mb-5 block" dangerouslySetInnerHTML={{ __html: col?.heading }} />}

										{/* Items */}
										<div className="flex flex-col gap-5">
											{col?.items?.map((item, index) => {
												return (
													<Fragment key={`contact-grid-col-item-${index}`}>
														{item?.type === "text" && (
															<div
																className={clsx(
																	col?.heading === "Address" ? "contact-grid__address" : "!leading-[1.80]",
																	"text-16 prose !font-normal !text-dark-purple/80"
																)}
																dangerouslySetInnerHTML={{ __html: item?.text }}
															/>
														)}
														{item?.type === "link" && (
															<Button newTab type={ButtonType.Text} icon={IconType.ArrowRightDown} link={{ title: item?.link?.title, url: item?.link?.url }} />
														)}
													</Fragment>
												);
											})}
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</Section>
		</div>
	);
};

export default ContactGrid;

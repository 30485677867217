// Layout 1 - Three Col Left Aligned
// Layout 2 - Three Col Center Aligned
// Layout 3 - Three Col Left Aligned Alt
// Layout 4 - Two Col Left Aligned

import React from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";
import { WpPage_Page_FlexibleContent_Usps } from "src/_generated/types";
import LottieAnimation from "~/components/elements/LottieAnimation";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { UspsItemCentered } from "./UspsItemCentered";
import UspsItemLeftAligned from "./UspsItemLeftAligned";
import Disclaimer from "./Disclaimer";
import UspsItemAlt2 from "./UspsItemAlt2";

export default function USPs({ heading, content, usps, section, disclaimer, hideDisclaimer, animation, layout }: WpPage_Page_FlexibleContent_Usps) {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.3,
	});

	return (
		<Section {...section}>
			<div className="container relative">
				<div className={clsx(layout === "3" && `border-l border-l-purple ${animation ? "overflow-hidden md:w-[110%] xxl:overflow-visible" : ""}`)}>
					<div className={`flex ${layout === "2" ? "justify-center text-center" : ""}${layout === "3" ? "flex-col" : ""} `}>
						{/* Top Heading, content, animation */}
						<div className={clsx("relative z-10 space-y-8 pb-2 pt-1", layout === "3" && "w-full max-w-[90%] md:mb-16 lg:mb-0 lg:max-w-full")}>
							{/* Heading */}
							<div
								className={clsx(
									layout === "3" && "ml-6 max-w-[1103px] md:ml-[44px]",
									layout === "4" && "max-w-[850px]",
									layout !== "3" && layout !== "4" && "max-w-[940px]"
								)}
							>
								<Heading as={layout === "3" ? "h3" : "h2"} size={layout === "3" ? HeadingSizeOption.h3 : HeadingSizeOption.h2} text={heading} />
							</div>

							{/* Content */}
							{content && (
								<div className="max-w-[688px]">
									<BodyCopy text={content} size={BodyCopySizeOption.Large} />
								</div>
							)}
						</div>

						{/* Animation */}
						{animation && (
							<div className="relative -mt-32 ml-[-46px] hidden w-[105%] md:block lg:ml-[-60px] xxl:ml-[-63px] xxl:w-[115%]" ref={ref}>
								<LottieAnimation loop={true} play={inView} lazyData={animation} />
							</div>
						)}
					</div>

					{/* Items */}
					<div
						className={clsx(
							"md:-ml-5x flex flex-wrap md:mt-12",
							layout === "2" && "lg:ml-[-27px]x md:mt-[70px]",
							layout === "3" && "ml-6 md:ml-0",
							layout === "3" && `${animation ? "md:mt-[-90px] md:w-[90%]" : "md:mt-[240px]"}`,
							layout !== "2" && layout !== "3" && "lg:ml-[-52px]x mt-12 md:mt-[98px]",
							layout === "1" && "gap-12 md:gap-0",
							(layout === "2" || layout === "3") && "divide-y-[1px] divide-purple-2 md:divide-x-[1px] md:divide-y-0",
							layout === "4" ? "grid grid-cols-1 gap-12 sm:grid-cols-2 lg:gap-20" : "grid grid-cols-1 md:grid-cols-3"
						)}
					>
						{usps?.length > 0 &&
							usps?.map((item, i) => (
								<div key={`usps-items-${i}`} className={clsx("w-full")}>
									{layout === "3" ? (
										<UspsItemAlt2 key={i} i={i} {...item} />
									) : layout === "2" ? (
										<UspsItemCentered key={i} i={i} {...item} />
									) : (
										<UspsItemLeftAligned key={i} {...item} layout={layout} />
									)}
								</div>
							))}
					</div>
				</div>
			</div>

			{/* Disclaimer */}
			{!!disclaimer && !hideDisclaimer && <Disclaimer disclaimer={disclaimer} />}
		</Section>
	);
}

import React from "react";
import { Section } from "~/components/elements/Section";
import { WpPage_Page_FlexibleContent_Hero } from "src/_generated/types";
import DefaultHero from "./DefaultHero";
import HomeHero from "./HomeHero";
import WhatWeDoHero from "./WhatWeDoHere";
import AboutHero from "./AboutHero";
import MSFHero from "./MSFHero";
import CareersHero from "./CareersHero";
import ContactHero from "./ContactHero";

export default function Hero({
	subheading,
	heading,
	headingMaxWidth,
	content,
	links,
	image,
	section,
	layout,
	whatWeDo,
	subheadingIcon,
	about,
	msf,
	contact,
	hideGridlines,
}: WpPage_Page_FlexibleContent_Hero) {
	return (
		<>
			<Section {...section} heroLayout={layout}>
				<div className={`overflow-hidden layout-${layout}`}>
					{layout === "what-we-do" ? (
						<WhatWeDoHero
							subheading={subheading}
							subheadingIcon={subheadingIcon}
							heading={heading}
							headingMaxWidth={headingMaxWidth}
							content={content}
							links={links}
							{...whatWeDo}
						/>
					) : layout === "home" ? (
						<HomeHero heading={heading} content={content} image={image} links={links} />
					) : layout === "about" ? (
						<AboutHero subheadingIcon={subheadingIcon} subheading={subheading} heading={heading} image={image} links={links} about={about} />
					) : layout === "careers" ? (
						<CareersHero subheadingIcon={subheadingIcon} subheading={subheading} heading={heading} image={image} />
					) : layout === "msf" ? (
						<MSFHero subheadingIcon={subheadingIcon} subheading={subheading} heading={heading} image={image} links={links} {...msf} />
					) : layout === "contact" ? (
						<ContactHero subheadingIcon={subheadingIcon} subheading={subheading} heading={heading} image={image} links={links} {...contact} />
					) : (
						<DefaultHero
							subheadingIcon={subheadingIcon}
							subheading={subheading}
							heading={heading}
							headingMaxWidth={headingMaxWidth}
							content={content}
							image={image}
							links={links}
							section={section}
							hideGridlines={hideGridlines}
						/>
					)}
				</div>
			</Section>
			{/* {layout === "what-we-do" && (
        <div className="w-0.5 h-32 mx-auto bg-dark-purple relative z-10" />
      )} */}
		</>
	);
}

import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_ContactForm } from "~/_generated/types";
import InputField from "~/components/elements/forms/InputField";
import TextareaField from "~/components/elements/forms/TextareaField";
import { Icon, IconType } from "~/components/elements/Icon";
import { countries } from "../elements/forms/Countries";
import useGlobal from "~/hooks/useGlobal";

const validationSchema = Yup.object({
	firstname: Yup.string().required("Required"),
	lastname: Yup.string().required("Required"),
	email: Yup.string().email("Invalid email address").required("Required"),
	company: Yup.string(),
	jobFunction: Yup.string(),
	telephone: Yup.string(),
	country: Yup.string(),
	message: Yup.string(),
});

export default function ContactForm({ section, heading }: WpPage_Page_FlexibleContent_ContactForm) {
	const [submitted, setIsSubmitted] = useState(false);

	return (
		<Section {...section}>
			<div className="container">
				<div className="mx-auto text-center">
					<Heading as="h3" size={HeadingSizeOption.h3} text={submitted ? "Thank you for getting in touch! " : heading} />

					<div className="mx-auto mt-10 max-w-[588px] md:mt-[60px]">
						{!submitted ? (
							<ContForm setIsSubmitted={setIsSubmitted} />
						) : (
							<div>
								<div>Someone will be in contact shortly</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</Section>
	);
}

export const ContForm = ({ setIsSubmitted }) => {
	const [errorMessage, setErrorMessage] = useState("");

	const { global } = useGlobal();

	return (
		<Formik
			initialValues={{
				firstname: "",
				lastname: "",
				email: "",
				company: "",
				jobFunction: "",
				telephone: "",
				country: "",
				message: "",
			}}
			validationSchema={validationSchema}
			onSubmit={async (values) => {
				const formData = new FormData();

				for (var key in values) {
					formData.append(key, values[key]);
				}

				fetch("https://getform.io/f/dc86f3e7-bb34-4c5b-9245-68bc2af35fba", {
					method: "POST",
					body: formData,
				})
					.then((response) => {
						if (response.status === 200) {
							setIsSubmitted(true);
						}
					})
					.catch((error) => {
						setErrorMessage(error);
					});
				setErrorMessage(""); // reset error message
			}}
		>
			{(props) => (
				<Form className="mt-9">
					<div className="-ml-6 mb-4 md:flex">
						{/* First name */}
						<div className="mb-4 w-full pl-6 md:mb-0">
							<InputField name="firstname" label="First name*" />
						</div>

						{/* Last name */}
						<div className="w-full pl-6">
							<InputField name="lastname" label="Last name*" />
						</div>
					</div>

					{/* Email */}
					<div className="mb-4">
						<InputField name="email" type="email" label="Email*" />
					</div>

					{/* Company name */}
					<div className="mb-4">
						<InputField name="company" label="Company Name" />
					</div>

					{/* Job function, country */}
					<div className="-ml-6 mb-4 flex flex-col gap-4 md:flex-row">
						{/* Job function */}
						<div className="relative w-full pl-6">
							<label className="flex flex-auto flex-col items-start text-[16px] font-medium leading-[1.65] text-black">
								<div className="mb-[7px]">Job function</div>
								<Field className={`hide-dropdown relative w-full rounded-md border border-black/20 px-4 py-2 text-black`} as="select" name="jobFunction">
									<option className="opacity-[0.37]">Please select...</option>
									{global?.form?.jobFunctions?.map((job) => (
										<option key={job?.job} value={job?.job}>
											{job?.job}
										</option>
									))}
								</Field>
							</label>
							<div className="absolute right-6 top-[66%]">
								<Icon type={IconType.DropDown} />
							</div>
						</div>

						{/* Country */}
						<div className="relative w-full pl-6">
							<label className="flex flex-auto flex-col items-start text-[16px] font-medium leading-[1.65] text-black">
								<div className="mb-[7px]">Country</div>
								<Field className={`hide-dropdown relative w-full rounded-md border border-black/20 px-4 py-2 text-black`} as="select" name="country">
									<option className="opacity-[0.37]">Please select...</option>
									{countries?.map((country) => (
										<option key={country} value={country}>
											{country}
										</option>
									))}
								</Field>
							</label>
							<div className="absolute right-6 top-[66%]">
								<Icon type={IconType.DropDown} />
							</div>
						</div>
					</div>

					{/* Telephone */}
					<div className="mb-4">
						<InputField name="telephone" label="Phone number" />
					</div>

					{/* Your message */}
					<div className="mb-4">
						<TextareaField name="message" label="Your message" />
					</div>

					{/* Submit */}
					<div className="mt-11 flex justify-start">
						<button type="submit" className="solid-btn rounded-[4px] bg-purple-2 px-[34px] py-[20px] hover:bg-dark-purple lg:px-[49px]">
							<div className="flex w-full items-center justify-center">
								<span className="font-normal">Send Enquiry</span>
								<div className="ml-[16px] inline-block">
									<Icon type={IconType.ArrowRightDown} />
								</div>
							</div>
						</button>
					</div>
					{errorMessage?.length > 0 && <div className="text-red mt-4" dangerouslySetInnerHTML={{ __html: errorMessage }} />}
				</Form>
			)}
		</Formik>
	);
};

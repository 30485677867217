import React from "react";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Icon } from "~/components/elements/Icon";
import { Button } from "./buttons/Button";
import clsx from "clsx";
// import { useMeasure } from "react-use";

export default function Note({
	noteBackgroundColour,
	noteHeading,
	noteContent,
	noteLink,
	noteIcon,
	noteType,
	active = true,
	showChevron = false,
	speechBubble = false,
	flip = false,
	largeCopy = false,
	classname = "",
	noMaxWidth = false,
}) {
	let bgColour = "bg-purple-2 bg-opacity-[.08]";
	let iconColour = "text-purple-2 text-opacity-[0.8]";
	let headingColour = "text-black";
	let contentColour = "text-black";
	let padding = "md:p-[22px] p-5";

	const chevronColorMap = {
		purple: "text-purple-1",
		"light-purple": "text-purple-1",
		"dark-purple": "text-purple-1",
		"dark-purple-2": "text-purple-1",
		green: "text-green-2",
		orange: "text-orange-1",
		"blue-3": "text-vibrant-blue",
		"orange-3": "text-orange-1",
		"green-4": "text-green-1",
		"purple-4": "text-purple-1",
	};

	switch (noteBackgroundColour) {
		case "transparent":
			bgColour = "bg-transparent";
			padding = "px-0 py-0";
			break;
		case "purple":
			bgColour = "bg-purple-2";
			iconColour = "text-purple-2";
			break;
		case "light-purple":
			bgColour = "bg-[#f3f3fc]";
			iconColour = "text-[#f3f3fc]";
			break;
		case "dark-purple":
			bgColour = "bg-dark-purple";
			iconColour = "text-dark-purple";
			break;
		case "dark-purple-2":
			bgColour = "bg-[#141933]";
			iconColour = "text-[#141933]";
			break;
		case "green":
			bgColour = "bg-[#DBFFF0]";
			iconColour = "text-[#DBFFF0]";
			break;
		case "orange":
			bgColour = "bg-orange-4";
			break;
		case "blue-3":
			bgColour = "bg-blue-3";
			break;
		case "orange-3":
			bgColour = "bg-orange-3";
			break;
		case "green-4":
			bgColour = "bg-green-4";
			break;
		case "purple-4":
			bgColour = "bg-purple-4";
			break;
	}

	switch (noteHeading?.colour) {
		case "black":
			headingColour = "text-black";
			break;
		case "white":
			headingColour = "text-white";
			break;
		case "purple":
			headingColour = "text-purple-2";
			break;
		case "green":
			headingColour = "text-green-2";
			break;
		case "dark-green":
			headingColour = "text-dark-green-1";
			break;
		case "orange":
			headingColour = "text-orange-1";
			break;
		case "dark-orange":
			headingColour = "text-orange-1";
			break;
		case "grey":
			headingColour = "text-purple-2";
			break;
	}

	switch (noteContent?.colour) {
		case "black":
			contentColour = "text-black";
			break;
		case "white":
			contentColour = "text-white";
			break;
		case "purple":
			contentColour = "text-purple-2";
			break;
		case "green":
			contentColour = "text-green-1";
			break;
		case "orange":
			contentColour = "text-orange-1";
			break;
		case "grey":
			contentColour = "!opacity-60";
			break;
	}

	// const [ref, { height }] = useMeasure();

	return (
		<div
			// animate={{ height }}
			// transition={{
			// 	duration: 0.25,
			// 	ease: "easeOut",
			// }}
			className={clsx(bgColour, "note relative overflow-hidden rounded-2xl", !noMaxWidth && "md:max-w-[480px]", classname)}
		>
			<div
				className="w-full"
				// ref={ref}
			>
				{speechBubble && (
					<div
						className={clsx(
							"triangle",
							`triangle--${noteBackgroundColour}`,
							"absolute left-1/2 -translate-x-1/2 transform",
							flip ? "-bottom-8 rotate-180" : "-top-8"
						)}
					/>
				)}

				<div className={padding}>
					<div className={clsx("flex w-full items-start justify-between gap-8", headingColour)}>
						<div className="flex items-start justify-start gap-4">
							{/* Icon */}
							{noteHeading?.icon && noteHeading?.icon !== "none" && (
								<div className={clsx(noteHeading.icon !== "eye" && "bg-current", "flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full")}>
									<div className={clsx("flex-shrink-0", noteHeading.icon === "eye" ? "h-5 w-5 text-current" : `h-[14px] w-[14px] ${iconColour}`, "fw-icon")}>
										<Icon type={noteHeading.icon} />
									</div>
								</div>
							)}

							<div className="flex flex-col items-start gap-4">
								{/* Note heading */}
								<div className="mt-[2px] text-left text-[18px] leading-[22px]">{noteHeading?.text}</div>

								{/* <AnimatePresence> */}
								{active && (
									<div
										// layout
										className={clsx(contentColour, "overflow-hidden")}
										// initial={{ opacity: 0 }}
										// animate={{
										// 	opacity: 1,
										// 	y: 0,
										// 	transition: { duration: 0.2, ease: "easeOut" },
										// }}
										// exit={{
										// 	opacity: 0,
										// 	transition: { duration: 0.1 },
										// }}
									>
										<div>
											{/* Text */}
											<div className="opacity-80">
												<BodyCopy className="text-left" text={noteContent?.text} size={largeCopy ? BodyCopySizeOption.Large : BodyCopySizeOption.Default} />
											</div>

											{/* Button */}
											{noteLink && (
												<div className="mt-3">
													<Button link={noteLink} type={noteType} icon={noteIcon} />
												</div>
											)}
										</div>
									</div>
								)}
								{/* </AnimatePresence> */}
							</div>
						</div>

						{/* Chevron */}
						{showChevron && (
							<div className={clsx("fw-icon flex-shrink-0 transition-transform duration-300 ease-out", !active && "rotate-180")}>
								<NoteChevron className={clsx(chevronColorMap?.[noteBackgroundColour] || "text-black")} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

const NoteChevron = ({ className = "text-purple-1" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 22 12" fill="none">
			<path d="M21 1L11 11L1 1" stroke="currentColor" className={className} strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	);
};

import React, { useState } from "react";
import { useField } from "formik";

export default function InputFieldAlt(props) {
	const { label, disabled, ...other } = props;
	const [field, meta, helpers] = useField(props);
	const isInvalid = meta.touched && meta.error;

	return (
		<div className={`group flex flex-col justify-center text-black`}>
			<div className="relative">
				<label
					className={`pointer-events-none absolute top-1/2 -translate-y-1/2 transform transition-transform duration-200 ease-in-out group-focus-within:-translate-y-10 group-hover:-translate-y-10 ${
						disabled && "text-opacity-30 group-hover:-translate-y-1/2"
					} ${(meta.value?.length > 0 || isInvalid) && "!-translate-y-10"} ${!isInvalid ? "text-navy" : "text-error"} `}
				>
					{label}
				</label>
				<input
					className={`outline-none w-full border-b border-b-black border-opacity-20 bg-black py-3.5 transition-colors duration-200 ease-in-out focus:border-opacity-100 disabled:text-opacity-30 group-hover:border-opacity-100 ${
						disabled && "group-hover:border-opacity-30"
					} ${!isInvalid ? "" : "!border-b-error"}`}
					{...field}
					{...other}
				/>
			</div>

			{isInvalid && (
				<div className="mt-1 flex items-center text-[12px] font-medium text-error">
					<div className="relative mr-1 mt-px h-2 w-2">
						<div className="absolute top-1/2 block h-0.5 w-full -translate-y-1/2 rotate-45 transform bg-error"></div>
						<div className="absolute top-1/2 block h-0.5 w-full -translate-y-1/2 -rotate-45 transform bg-error"></div>
					</div>
					{meta.error}
				</div>
			)}
		</div>
	);
}

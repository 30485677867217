import React from "react";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import StepsAnimation from "./StepsAnimation";

export const AltBlocks = ({ heading, blocks, animation }) => {
	return (
		<div className="container">
			<div className="border-padding-left-large border-l border-l-purple">
				<div className="mb-12 max-w-[850px] text-left md:mb-32">
					<Heading as="h2" size={HeadingSizeOption.h2} text={heading} />
				</div>
				<div className="relative grid md:grid-cols-2">
					<div className="space-y-12 md:space-y-52">
						{blocks.map((block, i) => (
							<div className="mr-auto max-w-[324px]">
								<div className="space-y-4">
									<div className="text-purple">
										<Heading as="h4" size={HeadingSizeOption.h4} text={block.heading} />
									</div>
									<div className="purple-bullet-list prose">
										<BodyCopy text={block.content} size={BodyCopySizeOption.Default} />
									</div>
								</div>
							</div>
						))}
					</div>

					<div className={`hidden w-full flex-auto md:block`}>
						<StepsAnimation animation={animation} />
					</div>
				</div>
			</div>
		</div>
	);
};

import React, { useState, useRef, useLayoutEffect, ReactNode } from "react";
import { motion, useViewportScroll, useTransform, useSpring } from "framer-motion";

type ParallaxProps = {
	children: ReactNode;
	offset?: number;
	direction?: string;
};

const ScrollMove = ({ children, offset = 50, direction = "y", reverse }: ParallaxProps): JSX.Element => {
	const [elementTop, setElementTop] = useState(0);
	const [clientHeight, setClientHeight] = useState(0);
	const ref = useRef(null);

	const { scrollY } = useViewportScroll();

	const initial = elementTop - clientHeight;
	const final = elementTop + offset;

	const yRange = useTransform(scrollY, [initial, final], [offset, -offset]);
	const xRange = useTransform(scrollY, [initial, final], [-offset, offset]);
	const xRangeReverse = useTransform(scrollY, [initial, final], [offset, -offset]);
	const y = useSpring(yRange, { stiffness: 400, damping: 90 });
	const x = useSpring(reverse ? xRangeReverse : xRange, {
		stiffness: 400,
		damping: 90,
	});

	useLayoutEffect(() => {
		const element = ref.current;
		const onResize = () => {
			setElementTop(element.getBoundingClientRect().top + window.scrollY || window.pageYOffset);
			setClientHeight(window.innerHeight);
		};
		onResize();
		window.addEventListener("resize", onResize);
		return () => window.removeEventListener("resize", onResize);
	}, [ref]);

	if (direction === "y") {
		return (
			<motion.div
				ref={ref}
				style={{
					y,
				}}
			>
				{children}
			</motion.div>
		);
	}

	if (direction === "x") {
		return (
			<motion.div
				ref={ref}
				style={{
					x,
				}}
			>
				{children}
			</motion.div>
		);
	}
};

export default ScrollMove;

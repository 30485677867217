import React from "react";
import { Section } from "~/components/elements/Section";
import { WpPost_Article_FlexibleContent_Text } from "src/_generated/types";

export default function Text({ content, section }: WpPost_Article_FlexibleContent_Text) {
	return (
		<Section
			{...section}
			paddingTop="none"
			paddingBottom="none"
			classname={`${section.backgroundColour !== "transparent" && "pt-6 md:pt-12 !pb-6 md:!pb-12 mb-6 md:mb-12 px-8 md:px-16"} ${
				section.backgroundColour === "white" && "text-black"
			}`}
		>
			<div dangerouslySetInnerHTML={{ __html: content }} className="p-large prose" />
		</Section>
	);
}

import React, { useState } from "react";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_MsfForm } from "~/_generated/types";

import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import InputFieldAlt from "~/components/elements/forms/InputFieldAlt";
import { Icon, IconType } from "~/components/elements/Icon";
import useGlobal from "~/hooks/useGlobal";

const validationSchema = Yup.object({
	report: Yup.array().of(Yup.string()).required("Required"),
	firstname: Yup.string().required("Required"),
	lastname: Yup.string().required("Required"),
	email: Yup.string().email("Invalid email address").required("Required"),
	company: Yup.string(),
	jobFunction: Yup.string(),
});

export default function MsfForm({ heading, image, section }: WpPage_Page_FlexibleContent_MsfForm) {
	const [submitted, setIsSubmitted] = useState(false);

	return (
		<Section {...section}>
			<div className="container">
				<div className="grid grid-cols-1 overflow-hidden rounded-[10px] md:grid-cols-12">
					<div className="col-span-7 space-y-20 bg-white px-6 py-12 text-purple md:py-20 md:pl-24 md:pr-12">
						<Heading type="h2" size={HeadingSizeOption.h2} text={submitted ? "Thank you for registering" : heading} />
						<div className="">
							{!submitted ? (
								<MsfFormForm setIsSubmitted={setIsSubmitted} />
							) : (
								<div className="mx-auto flex justify-center">You will recieve a response shortly</div>
							)}
						</div>
					</div>
					<div className="col-span-5 flex flex-col justify-center bg-[#D6D6FF] px-6 py-12 md:px-24 md:py-20">
						<Image image={image} />
					</div>
				</div>
			</div>
		</Section>
	);
}

export const MsfFormForm = ({ setIsSubmitted }) => {
	const [errorMessage, setErrorMessage] = useState("");

	const { global } = useGlobal();

	return (
		<Formik
			initialValues={{
				report: [],
				firstname: "",
				lastname: "",
				email: "",
				company: "",
				jobFunction: "",
			}}
			validationSchema={validationSchema}
			onSubmit={async (values) => {
				const formData = new FormData();

				for (var key in values) {
					formData.append(key, values[key]);
				}

				fetch("https://getform.io/f/9ee8df46-b600-4594-a024-7d9588a61865", {
					method: "POST",
					body: formData,
				})
					.then((response) => {
						if (response.status === 200) {
							setIsSubmitted(true);
							const urls = [];
							if (values.report.includes("Managed Security and 3rd Party Risk Opportunity")) {
								urls.push("/pdfs/MSF_Managed_Security_and_the_3rd_Party_Risk_Opportunity_May-2021.pdf");
							}
							if (values.report.includes("Managed Security – Moving Beyond Detection")) {
								urls.push("/pdfs/Managed_Security_Moving_Beyond_Detection_2020_final.pdf");
							}
							if (values.report.includes("MSF Guide to UK Managed Security")) {
								urls.push("/pdfs/MSF_The_Guide_to_UK_Managed_Security_2018.pdf");
							}
							let interval = setInterval(download, 300, urls);
							function download() {
								const url = urls.pop();
								const a = document.createElement("a");
								a.setAttribute("href", url);
								a.setAttribute("download", "");
								a.setAttribute("target", "_blank");
								a.click();
								if (urls.length == 0) {
									clearInterval(interval);
								}
							}
						}
					})
					.catch((error) => {
						setErrorMessage(error);
					});
				setErrorMessage(""); // reset error message
			}}
		>
			{(props) => (
				<Form className="mt-9 grid grid-cols-2 gap-8">
					<div className="col-span-2">
						<div className={`group relative flex items-center text-black`}>
							<label className={`absolute top-1/2 -translate-y-20 transform text-sm`}>Reports*</label>
							<Field
								as="select"
								name="report"
								multiple={true}
								className={`outline-none border-b-navy w-full appearance-none border-b border-b-black border-opacity-30 bg-transparent px-0 py-3.5 transition-colors duration-200 ease-in-out hover:border-opacity-100 focus:border-opacity-100`}
							>
								<option value="" disabled>
									Please Select
								</option>
								{global?.form?.guides?.length > 0 &&
									global?.form?.guides?.map((option) => (
										<option key={option?.guide} value={option?.guide} dangerouslySetInnerHTML={{ __html: option?.guide }} />
									))}
							</Field>
						</div>
					</div>

					<div className="col-span-2 md:col-span-1">
						<InputFieldAlt name="firstname" label="First Name*" />
					</div>
					<div className="col-span-2 md:col-span-1">
						<InputFieldAlt name="lastname" label="Last Name*" />
					</div>
					<div className="col-span-2 md:col-span-1">
						<InputFieldAlt name="email" type="email" label="Email*" />
					</div>
					<div className="col-span-2 md:col-span-1">
						<InputFieldAlt name="company" label="Company Name" />
					</div>

					<div className="col-span-2">
						<div className={`select group relative flex items-center text-black`}>
							<label className={`absolute top-1/2 -translate-y-10 transform text-sm`}>Job function*</label>
							<Field
								as="select"
								name="jobFunction"
								className={`outline-none border-b-navy w-full appearance-none border-b border-b-black border-opacity-30 bg-transparent px-0 py-3.5 transition-colors duration-200 ease-in-out hover:border-opacity-100 focus:border-opacity-100`}
							>
								<option value="" disabled>
									Please Select
								</option>
								{global?.form?.jobFunctions?.map((job) => (
									<option key={job?.job} value={job?.job}>
										{job?.job}
									</option>
								))}
							</Field>
						</div>
					</div>

					<div className="mt-11 flex justify-start">
						<button type="submit" className="solid-btn rounded-[4px] bg-purple-2 px-[34px] py-[20px] hover:bg-dark-purple lg:px-[49px]">
							<div className="flex w-full items-center justify-center">
								<span className="font-normal">Send Enquiry</span>
								<div className="ml-[16px] inline-block">
									<Icon type={IconType.ArrowRightDown} />
								</div>
							</div>
						</button>
					</div>
					{errorMessage?.length > 0 && <div className="text-red mt-4" dangerouslySetInnerHTML={{ __html: errorMessage }} />}
				</Form>
			)}
		</Formik>
	);
};

import React from "react";

import { BodyCopy } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";

import { WpPage_Page_FlexibleContent_Newsletter } from "~/_generated/types";
import { Icon, IconType } from "~/components/elements/Icon";

export default function Newsletter({ heading, content, section }: WpPage_Page_FlexibleContent_Newsletter) {
	return (
		<Section {...section}>
			<div className="container space-y-12">
				<div className="space-y-3.5">
					<div className="text-purple">
						<Heading text={heading} type="h2" size={HeadingSizeOption.h2} />
					</div>
					<BodyCopy text={content} />
				</div>
				{/* @TODO: Tidy this up + hook up to a service */}
				<form className="flex">
					<input
						type="text"
						className="outline-none flex-auto border-b border-t border-black border-opacity-20 text-[21px] placeholder-black"
						placeholder="Email Address*"
					/>
					<button className="group border-b border-l border-t border-black border-opacity-20 px-12 py-6">
						<div className="transition-opacity duration-300 ease-in-out group-hover:opacity-50">
							<Icon type={IconType.ArrowRight} />
						</div>
					</button>
				</form>
			</div>
		</Section>
	);
}

import React from "react";
import Float from "~/components/animations/Float";
import Image from "~/components/elements/Image";
import GridLines from "~/components/elements/GridLines";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import ButtonGroup from "~/components/elements/buttons/ButtonGroup";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Subheading } from "~/components/elements/typography/Subheading";
import clsx from "clsx";
import { useWindowSize } from "react-use";

const DefaultHero = ({ subheadingIcon, subheading, heading, headingMaxWidth, content, image, links, section, hideGridlines }) => {
	let bgClass = "bg-[#B1FFDE]";
	let gridLinesColour = "45";

	switch (section.backgroundColour) {
		case "orange":
			bgClass = "bg-orange-2";
			gridLinesColour = "40";
			break;
		case "green":
			bgClass = "bg-[#B1FFDE]";
			break;
		case "blue":
			bgClass = "bg-blue-5";
			break;
		case "light-purple":
			bgClass = "bg-purple-3";
			break;
		case "dark-purple":
			bgClass = "bg-white bg-opacity-5";
			break;
	}

	const { width: windowWidth } = useWindowSize();

	const getHeadingMaxWidth = () => {
		if (windowWidth > 1024) {
			return headingMaxWidth || "605px";
		}

		return "100%";
	};

	return (
		<>
			{/* Gridlines */}
			{!hideGridlines && <GridLines colour={gridLinesColour} />}

			<div className="container">
				<div className="relative items-end justify-between space-y-[50px] lg:flex lg:space-x-[70px] lg:space-y-0">
					{/* Content col */}
					<div className="flex flex-col">
						{subheading && (
							<div className="">
								<Subheading text={subheading} icon={subheadingIcon} />
							</div>
						)}

						<div className="mt-6 flex-1 md:mt-10">
							<Border size={BorderSizeOption.Hero} colour={section?.backgroundColour === "dark-purple" ? "border-purple-2" : "border-black"} className="h-full">
								<div
									style={{
										maxWidth: getHeadingMaxWidth(),
									}}
									className={clsx("flex h-full flex-col justify-between", links ? "lg:pb-20" : "lg:pb-[94px]")}
								>
									<Heading as="h1" size={HeadingSizeOption.h1} text={heading} classes="!leading-[1.10]" />

									<div>
										<div className="mt-6 max-w-[512px] md:mt-[50px] lg:mt-[188px]">
											<BodyCopy text={content} size={BodyCopySizeOption.Large} />
										</div>

										{links && (
											<div className="mt-6 md:mt-5">
												<ButtonGroup links={links} className="!w-full md:!w-auto" />
											</div>
										)}
									</div>
								</div>
							</Border>
						</div>
					</div>

					{/* Image col */}
					<div className={clsx(bgClass, "relative h-[400px] w-full rounded-t-[30px] md:h-[657px] lg:w-[485px]")}>
						<div className="absolute left-1/2 top-1/2 w-[110%] -translate-x-1/2 -translate-y-1/2 lg:left-[-3rem] lg:translate-x-0 xl:left-[-4rem]">
							<Float className="flex items-center justify-center">
								<Image image={image} className="default-hero-image h-full w-[70%] md:w-[60%] lg:w-[105%] lg:max-w-[650px]" />
							</Float>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DefaultHero;

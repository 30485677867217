import React, { useState, useRef, useEffect } from "react";

import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { Section } from "~/components/elements/Section";
import Note from "~/components/elements/Note";
import { WigglyLine } from "~/components/elements/WigglyLine";

import { WpPage_Page_FlexibleContent_WhatWeDo } from "~/_generated/types";
import { Icon, IconType } from "~/components/elements/Icon";

export default function WhatWeDo({ heading, content, note1, note2, section }: WpPage_Page_FlexibleContent_WhatWeDo) {
	let [progress, setProgress] = useState(40);
	const containerRef = useRef();

	useEffect(() => {
		const top = containerRef.current.offsetTop - 100;
		const height = containerRef.current.offsetHeight + 100;

		const onScroll = (e) => {
			const scrollOffset = e.target.documentElement.scrollTop - top;
			if (scrollOffset <= 0) {
				setProgress(0);
				return;
			} else if (scrollOffset >= height) {
				setProgress(100);
			} else {
				setProgress((scrollOffset / height) * 100);
			}
		};
		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	return (
		<Section {...section}>
			<div className="container" ref={containerRef}>
				<div className="border-padding-left-large max-w-[520px] space-y-3 border-l border-l-purple">
					<div className="max-w-[450px]">
						<Heading type="h3" size={HeadingSizeOption.h3} text={heading} />
					</div>
					<div className="max-w-[483px]">
						<BodyCopy size={BodyCopySizeOption.Large} text={content} />
					</div>
				</div>
			</div>
			<div className="mt-6 overflow-hidden md:mt-[60px] lg:-mt-20">
				<div className="container mb-[20px] flex justify-end md:mb-[75px]">
					<div className="md:mr-16">
						<Note {...note1} speechBubble={true} flip={true} />
					</div>
				</div>
				<WigglyLine width={progress} />
				<div className="container mt-[20px] md:mt-20">
					<div className="md:ml-40">
						<Note {...note2} speechBubble={true} />
					</div>
				</div>
			</div>
		</Section>
	);
}

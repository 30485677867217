import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import animationData from "../../../../static/analytics-animation.json";
import { useInView } from "react-intersection-observer";
import { useWindowSize, useMeasure } from "react-use";

export const StepsAnimation = () => {
	const lottieRef = useRef();
	// const [progress, setProgress] = useState(0);
	// const { y, height } = useScroll(window);
	const [isPaused, setIsPaused] = useState(false);
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.3,
	});
	const { height: windowHeight, width: windowWidth } = useWindowSize();
	const [measureRef, { height }] = useMeasure();

	// useEffect(() => {
	// 	const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
	// 	const scrollPosition = Math.max(0, Math.min(y, scrollHeight));
	// 	const scrollProgress = scrollPosition / scrollHeight;
	// 	setProgress(scrollProgress);
	// }, [y]);

	// const options = {
	// 	animationData: animationData,
	// };

	// const style = {
	// 	height: "100%",
	// 	width: "100%",
	// 	maxWidth: "676px",
	// };
	// const lottieObj = useLottie(options, style);

	// const animation = useLottieInteractivity({
	// 	lottieObj,
	// 	mode: "scroll",
	// 	actions: [
	// 		{
	// 			visibility: [0.3, 0.8],
	// 			type: "seek",
	// 			frames: [200, 600],
	// 		},
	// 	],
	// });

	useEffect(() => {
		if (inView) {
			setIsPaused(false);
		} else {
			setIsPaused(true);
		}
	}, [inView]);

	useEffect(() => {
		if (!lottieRef?.current) return;

		if (isPaused) {
			lottieRef.current.pause();
		} else {
			lottieRef.current.play();
		}
	}, [isPaused]);

	const stickyTop = windowHeight / 2 - height / 2;

	const getTop = () => {
		if (windowWidth > 1920) {
			return stickyTop;
		} else {
			return "7rem";
		}
	};

	return (
		<div
			ref={measureRef}
			className="sticky w-full"
			style={{
				top: getTop(),
			}}
		>
			<div
				ref={ref}
				className="w-full max-w-[676px]"
				style={{
					aspectRatio: "676/700",
				}}
			>
				{/* {animation} */}
				<Lottie lottieRef={lottieRef} animationData={animationData} loop={false} style={{ height: "100%", width: "100%" }} />
			</div>
		</div>
	);
};

// export const StepsAnimation = ({ animation }) => {
// 	const containerRef = useRef();
// 	const [playerInstance, setPlayerInstance] = useState(null);
// 	const [created, setCreated] = useState(false);

// 	let frames = [300, 600];
// 	let src = "/analytics-animation.json";

// 	if (animation === "solutions") {
// 		frames = [0, 714];
// 		src = "/solutions-animation.json";
// 	}

// 	const createAnimation = () => {
// 		if (created) return;

// 		setCreated(true);
// 		// setTimeout(() => {
// 		// }, 1000);

// 		return create({
// 			mode: "scroll",
// 			player: playerInstance,
// 			container: containerRef.current,
// 			actions: [
// 				{
// 					visibility: [0, 1],
// 					type: "seek",
// 					frames: frames,
// 				},
// 			],
// 		});
// 	};

// 	useEffect(() => {
// 		if (containerRef?.current && playerInstance) {
// 			createAnimation();
// 		}
// 	}, [containerRef, playerInstance]);

// 	return (
// 		<div ref={containerRef} className="relative h-full" id="steps-container">
// 			<div className="sticky top-40 w-full max-w-[676px]" style={{ aspectRatio: "676/700" }}>
// 				<Player
// 					lottieRef={(instance) => setPlayerInstance(instance)}
// 					rendererSettings={{
// 						filterSize: { width: "200%", height: "200%", x: "-50%", y: "-50%" },
// 					}}
// 					src={src}
// 				/>
// 			</div>
// 		</div>
// 	);
// };

export default StepsAnimation;

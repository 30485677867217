import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper";
import "swiper/css";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import { WpPage_Page_FlexibleContent_TwoColumnAccordion } from "~/_generated/types";
import { Icon } from "~/components/elements/Icon";
import { BgSwitcher } from "./TabbedSlider";
import Note from "~/components/elements/Note";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useWindowSize } from "react-use";

export default function TwoColumnAccordion(props: WpPage_Page_FlexibleContent_TwoColumnAccordion) {
	const { reverseLayout, heading, headingMaxWidth, largeHeading, largeHeadingMaxWidth, section, accordionItems, content, image } = props;

	const [activeIndex, setActiveIndex] = useState(0);
	const [swiper, setSwiper] = useState(null);

	// const interval = useRef(null);
	const slideTo = (index) => swiper?.slideTo(index);
	const { width: windowWidth } = useWindowSize();

	useLayoutEffect(() => {
		slideTo(activeIndex || 0);
	}, [activeIndex]);

	const [ref, inView] = useInView({
		threshold: 0.3,
	});

	// useEffect(() => {
	// 	if (inView) {
	// 		interval.current = setInterval(() => {
	// 			setActiveIndex((i) => (i + 1) % accordionItems.length);
	// 		}, 5000);
	// 	} else {
	// 		clearInterval(interval.current);
	// 		setActiveIndex(0);
	// 	}

	// 	return () => {
	// 		clearInterval(interval.current);
	// 	};
	// }, [interval, inView]);

	const onItemClickHandler = (i) => {
		setActiveIndex(i);
		// clearInterval(interval.current);

		// interval.current = setInterval(() => {
		// 	setActiveIndex((i) => (i + 1) % accordionItems.length);
		// }, 5000);
	};

	const getLargeHeadingMaxWidth = () => {
		if (windowWidth > 1024) {
			return largeHeadingMaxWidth || "100%";
		}

		return "100%";
	};

	const getHeadingMaxWidth = () => {
		if (windowWidth > 1024) {
			return headingMaxWidth || "100%";
		}

		return "100%";
	};

	return (
		<Section {...section}>
			<div className="container" ref={ref}>
				{/* Large heading */}
				{!!largeHeading && (
					<div
						style={{
							maxWidth: getLargeHeadingMaxWidth(),
						}}
					>
						<h2 dangerouslySetInnerHTML={{ __html: largeHeading }} className="text-48 mb-10 md:mb-20" />
					</div>
				)}

				<div className={clsx("flex flex-col items-start justify-between gap-10 lg:gap-20", reverseLayout ? "md:flex-row-reverse" : "md:flex-row")}>
					{/* Image Col */}
					<div className="w-full flex-1">
						<div className="w-full">
							{!!image ? (
								<Image image={image} objectFit="cover" className="w-full rounded-md" />
							) : (
								<Swiper onSwiper={setSwiper} modules={[A11y]} className={`w-full items-stretch rounded-md`} slidesPerView={1} draggable={false}>
									{accordionItems.map((item, i) => (
										<SwiperSlide style={{ alignSelf: "stretch" }} key={`tabbedswiper${i}`} className="!h-[70%] rounded-md md:!h-auto">
											<div className="h-full overflow-hidden rounded-md">
												<Image image={item?.image} objectFit="cover " className="h-full max-h-[450px] rounded-md md:max-h-full" />
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							)}
						</div>
					</div>

					{/* Content Col */}
					<div className="m-0 w-full md:max-w-[50%] xl:max-w-[588px]">
						<div className="w-full">
							{/* Heading */}
							<div
								style={{
									maxWidth: getHeadingMaxWidth(),
								}}
							>
								<Heading as="h3" size={HeadingSizeOption.h3} text={heading} />
							</div>

							{/* Text */}
							<div className="mt-[21px] max-w-[1438px]">
								<BodyCopy text={content} size={BodyCopySizeOption.Large} />
							</div>

							{/* Accordion items */}
							<div className="mt-8 space-y-3">
								{accordionItems?.map((item, i) => (
									<AccordionItem key={`accordion-item-${i}`} activeIndex={activeIndex === i} onClick={() => onItemClickHandler(i)} {...item} />
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Section>
	);
}

export const IconBgSwitcher = ({ icon, backgroundColour = "white", active }) => {
	let bgColour = "bg-white";

	switch (backgroundColour) {
		case "purple":
			bgColour = "!bg-purple-2";
			break;
		case "green":
			bgColour = "!bg-green-1";
			break;
		case "orange":
			bgColour = "!bg-orange-1";
			break;
		case "white":
			bgColour = "!bg-white";
			break;
	}

	return (
		<div className={`${!active ? bgColour : "bg-white"} flex max-w-max items-center justify-between rounded-full p-[5px]`}>
			<div className="h-auto w-[10px]">
				<Icon type={icon} />
			</div>
		</div>
	);
};

export const AccordionItem = ({ note, activeIndex, onClick }) => {
	return (
		<BgSwitcher
			className="rounded-[10px]"
			onClick={() => {
				onClick?.();
			}}
		>
			<Note {...note} active={activeIndex} showChevron noMaxWidth />
		</BgSwitcher>
	);
};
